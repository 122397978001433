/*
 * Font icons.
 */
%font-nm {
	font-family: 'nm';
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	text-align: center;
	text-decoration: inherit;
}





/**
 * Default box style.
 */
%c-box {
	position: relative;

	display: flex;
	flex-flow: column nowrap;

	border: 2px solid;
	border-color: #765a45 #3f2c1d #3f2c1d #755946;
	border-radius: 1px;

	background-color: #543c2a;


	&:before {
		content: '';

		position: absolute;
		top: -4px;
		left: -4px;
		right: -4px;
		bottom: -4px;

		border: 2px solid #000;
		border-radius: 3px;
	}


	&.-c-hide {
		&:after {
			content: '';

			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;

			background-color: rgba(#543C2A, 0.8);

			z-index: 2;
		}
	}
}