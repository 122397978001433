.c-arena-box {
	$c: &;

	@extend %c-box;


	flex-flow: row nowrap;
	align-items: center;

	height: 35px;

	text-shadow: 1px 1px 1px #000;


	&__rating {
		position: relative;

		flex: 0 0 45px;
		width: 45px;

		font-size: 0.675rem;
		text-align: right;

		color: #A88B68;
	}

	&__rank {
		position: relative;

		flex: 0 0 35px;
		width: 35px;
		min-width: 35px;
	}

	&__clan {
		position: relative;
		flex: 0 0 40px;
		width: 40px;

		margin: 0 2px;
		padding: 0 1px;

		font-size: 0.675rem;
		text-align: center;

		a {
			text-decoration: none;

			color: #c18b65;
		}
	}

	&__online {
		flex: 0 0 10px;
		width: 10px;

		margin: 0 5px 0 0;
	}

	&__name {
		position: relative;

		flex: 1 1 auto;
		min-width: 0;

		margin: 0 5px 0 5px;

		font-size: 0.775rem;
		font-weight: 600;
		text-overflow: ellipsis;
		white-space: nowrap;

		padding: 0 1px 0 1px;

		color: #EDC896;

		overflow: hidden;


		a {
			text-decoration: none;

			color: #EDC896;
		}

		em {
			padding-left: 10px;

			font-size: 0.7rem;

			color: #d49542;


			&.-c-active {
				color: #bed24f;
			}
		}


		// component
		.c-resource {
			display: inline-flex;

			margin-left: 10px;


			span {
				padding-left: 8px;
			}
		}


		&.-valign-center {
			display: flex;
			align-items: center;
		}
	}

	&__icons {
		flex: 0 1 auto;

		display: flex;
		align-items: center;

		margin: 0 20px 0 5px;


		i {
			padding: 2px 0;

			font-size: 12px;

			color: #80664b;
		}

		.icon-kage-hat {
			padding: 2px 4px;

			font-size: 22px;
		}

		.icon-dharma {
			color: #ca886f;//a68767;
		}
	}

	&__village {
		flex: 0 0 25px;
		width: 25px;

		margin: 0 15px 0 5px;

		font-size: 18px;

		color: #A68767;
	}

	&__inspect {
		position: relative;

		flex: 0 0 30px;
		width: 30px;

		margin: 0 15px 0 5px;
		padding: 2px 0;

		font-size: 18px;
		text-align: center;

		color: #A68767;

		cursor: pointer;


		&:before {
			@extend %font-nm;

			content: '\e82b';
		}


		&:hover {
			color: #DABB9D;
		}
	}

	&__pow {
		position: relative;

		flex: 0 0 30px;
		width: 30px;

		margin: 0 15px 0 5px;


		// component
		&.c-pow {

		}
	}

	&__summary {
		flex: 0 1 auto;

		display: flex;

		margin: 0 15px 0 5px;


		// component
		.c-resource {
			margin: 0 0 0 3px;
		}
	}

	&__date {
		position: relative;

		flex: 0 0 70px;
		width: 70px;

		margin: 0 15px 0 5px;
		padding: 0 1px;

		font-size: 0.6rem;
		line-height: 1.1;
		text-align: right;

		color: #A88B68;
	}

	&__times {
		position: relative;

		flex: 0 0 20px;
		width: 20px;

		margin: 0 15px 0 5px;
		padding: 0 1px;

		font-size: 0.9rem;
		text-align: right;

		color: #ceab82;
	}

	&__replay {
		position: relative;

		flex: 0 0 30px;
		width: 30px;

		margin: 0 5px 0 5px;
		padding: 2px 0;

		font-size: 20px;
		text-align: center;

		color: #A68767;

		cursor: pointer;


		&:before {
			@extend %font-nm;

			content: '\e81b';
		}


		&:hover {
			color: #DABB9D;
		}
	}

	&__challenge {
		position: relative;
		right: -4px;

		flex: 0 0 90px;


		// component
		&.c-button {
			$button: '.c-button';

			width: 90px;
			height: 39px;


			span {
				margin-left: auto;
				padding-right: 14px;
			}


			#{$button}__icon {
				display: block;
				margin-right: -5px;
			}
		}
	}
}
