.c-card {
	$c: &;

	/**
	 * Default values are for -size-s modifier.
	 */

	position: relative;

	display: flex;
	flex-flow: column nowrap;

	width: 51px;
	min-width: 51px;
	max-width: 51px;
	min-height: 0;//64px;
	padding-bottom: 2px;

	border: 2px solid $c-card-border-color;
	border-radius: 2px;

	font-size: 1rem;

	background-color: $c-card-background-color;


	&:before {
		content: '';

		position: absolute;
		top: -3px;
		left: -4px;
		right: -4px;
		bottom: -5px;

		border-style: solid;
		border-color: $c-card-border-outer-color;
		border-width: 1px 2px 3px 2px;
		border-radius: 4px;
	}


	&__pic {
		position: relative;
		margin: 1px 1px -1px 1px;

		width: 45px;
		height: 50px;


		&:before {
			content: none;

			position: absolute;
			top: 1px;
			left: 1px;
			right: 1px;
			bottom: 1px;

			border: 1px solid rgba($c-card-pic-border-inset-color, 0.5);

			z-index: 1;
		}

		&:after {
			content: '';

			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;

			border: 1px solid $c-card-pic-border-inset-color;

			z-index: 1;
		}


		img {
			display: block;
			width: 45px;
			height: 50px;
		}

		&.-c-sepia {
			img {
				-webkit-filter: sepia(100%);
			}
		}

		&.-c-fade {
			img {
				position: absolute;
				top: 0;
				left: 0;

				animation: 12s linear 0s infinite picFade;

				&:nth-child(2) {
					animation-delay: 6s;
				}
			}
		}

		&.-c-shine {
			overflow: hidden;


			#{$c}__pic-inner {

				// TODO: change to picture
				/*
				&:before {
					content: '';

					position: absolute;
					top: 0;
					right: 0;
					bottom: 0;
					left: 0;

					background: linear-gradient(45deg, rgba(0,0,0,1) 10%, rgba(0,0,0,0) 50%);
				}
				*/

				&:after {
					content: '';

					position: absolute;
					top: -110%;
					left: -210%;

					width: 200%;
					height: 200%;
					transform: rotate(30deg);

					background: rgba(#A363AB, 0);
					background: linear-gradient(to right,
							rgba(#A363AB, 0) 0%,
							rgba(#A363AB, 1) 100%
					);

					animation: picShine 10s ease-in-out 0s infinite;
					animation-fill-mode: forwards;

					opacity: 0;
				}
			}
		}
	}

	&__pic-inner {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
	}

	&__offpos {
		position: absolute;
		top: 0;
		left: 0;

		display: none;
		width: 100%;
		height: 100%;

		text-align: center;

		background: rgba(#D60000, 0.5);


		b {
			position: absolute;
			right: 0;
			bottom: 5px;
			left: 0;

			margin: 0 auto;

			font-size: 0.9rem;
			letter-spacing: -1px;

			text-shadow: -1px -1px 1px #000,
						1px 1px 1px #000;

			color: #ffd5d5;
		}
	}

	&__details {
		position: relative;

		margin: 2px 2px 0 2px;


		&.-c-filled {
			min-height: 7px;

			background-color: #251A10;
		}
	}

	&__pos {
		top: -11px;
		left: 1px;

		z-index: 1;


		&.c-pos {
			position: absolute;
		}
	}

	&__pos-name {
		position: absolute;
		right: 0;
		bottom: 3px;
		left: 0;

		max-width: 100%;
		margin: 0 auto;
		padding: 0 2px;

		font-size: 0.6rem;
		font-weight: 700;
		text-transform: uppercase;
		text-align: center;
		text-shadow: 1px 1px 1px $c-card-text-shadow-color;

		color: $c-card-pos-name-color;

		overflow: hidden;
	}

	&__ele {
		top: 0;

		margin: 2px;

		z-index: 1;


		&.c-ele {
			position: absolute;
		}
	}

	&__name-search {
		position: absolute;
		top: 3px;
		right: 3px;
		left: 3px;

		white-space: nowrap;

		color: #e1c292;

		overflow: hidden;

		z-index: 0;
	}

	&__icons {
		position: absolute;
		top: 1px;
		right: 1px;

		display: flex;
		flex-flow: column nowrap;
		align-items:center;
		justify-content: center;

		width: 20px;
		min-height: 20px;

		border-radius: 0 0 0 1px;

		background-color: #000;

		z-index: 3;


		&:before {
			content: '';

			position: absolute;
			top: -1px;
			left: -1px;
			right: -1px;
			bottom: -1px;

			border-style: solid;
			border-color: rgba($c-card-pic-border-inset-color, 0.5);
			border-width: 0 0 1px 1px;
			border-radius: 0 0 0 2px;
		}


		i {
			font-size: 14px;

			color: #ECE3D6;
		}
	}

	&__lvl {
		position: absolute;
		top: 174px;
		right: 4px;

		text-align: right;

		z-index: 2;
	}

	&__lvl-text {
		display: none;
		margin-right: -4px;

		font-size: 0.5rem;
		text-shadow: 2px 1px 0 $c-card-text-shadow-color,
					-1px -1px 1px $c-card-text-shadow-color;

		color: $c-card-lvl-text-color;
	}

	&__lvl-nr {
		font-size: 0.9rem;
		letter-spacing: -1px;
		text-shadow: 1px 1px 0 $c-card-text-shadow-color,
					1px 2px 0 $c-card-text-shadow-color,
					-1px 0px 0 $c-card-text-shadow-color,
					0px 1px 0 $c-card-text-shadow-color,
					-1px 2px 0 $c-card-text-shadow-color,
					-1px -1px 0 $c-card-text-shadow-color,
					1px -1px 0 $c-card-text-shadow-color,
					-1px 1px 0 $c-card-text-shadow-color;

		color: $c-card-lvl-nr-color;
	}

	&__stats {
		position: relative;

		display: flex;
		flex-flow: row nowrap;

		height: 12px;

		margin: 2px 1px 0 1px;
	}

	&__stats-empty {
		flex: 1 1 auto;

		height: 100%;
		margin: 0 1px;

		background-color: #483728;
	}

	&__buki {
		margin: 0 1px;


		// component
		&.c-bar {
			height: 12px;
		}
	}

	&__buki-text {
		position: absolute;
		top: 4px;
		right: 0;

		z-index: 1;


		&:before {
			@extend %font-nm;

			content: '\e83b';

			position: absolute;
			top: -1px;
			left: 0;

			font-size: 18px;
			text-shadow: 1px 1px 1px #000,
						-1px 1px 0px #000,
						1px 0px 0px #000;

			color: #CEB486;
		}


		span {
			padding: 0 2px 0 19px;

			font-size: 0.6rem;
			text-shadow: 1px 1px 1px #000;

			color: #B19F7F;
		}
	}

	&__exp {
		position: relative;


		// component
		&.c-exp {
			width: auto;
			height: 7px;
		}
	}

	&__data {
		display: flex;
		flex-flow: row nowrap;

		height: 22px;
		margin: 2px 1px 0 1px;

		text-shadow: 1px 1px 0 #000;
	}

	&__chakra {
		position: relative;

		display: flex;
		align-items: center;
		justify-content: center;

		width: 48px;
		height: 100%;
		margin: 0 1px;

		background-color: #322214;


		&:before {
			@extend %font-nm;

			content: '\e803';

			position: absolute;
			top: 0;
			right: 5px;
			bottom: 0;

			display: flex;
			align-items: center;

			font-size: 16px;
			line-height: 1.1;

			color: #888;
		}


		span {
			position: relative;

			width: 100%;
			padding: 1px 26px 1px 1px;

			font-size: 0.8rem;
			text-align: right;
			letter-spacing: -1px;

			color: #bdbdbd;
		}
	}

	&__seal {
		position: relative;

		display: flex;
		align-items: center;
		justify-content: center;

		width: 42px;
		height: 100%;
		margin: 0 1px;

		background-color: #4D3B2A;


		&:before {
			@extend %font-nm;

			content: '\e80b';

			position: absolute;
			top: 0;
			right: 4px;
			bottom: 0;

			display: flex;
			align-items: center;

			font-size: 14px;
			line-height: 1.1;

			color: #977798;
		}


		span {
			position: relative;

			width: 100%;
			padding: 1px 20px 1px 1px;

			font-size: 0.8rem;
			text-align: right;
			letter-spacing: -1px;

			color: #d2b2d4;
		}
	}

	&__chakra,
	&__seal {
		&.-c-empty {
			&:before {
				content: none;
			}
		}
	}

	&__dmg {
		position: relative;

		flex: 1 1 auto;

		display: flex;
		flex-flow: column nowrap;
		align-items: center;
		justify-content: center;

		height: 100%;
		margin: 0 1px;
		padding: 2px 4px;

		text-align: center;

		background-color: #251A10;

		line-height: 1.1;


		span {
			position: relative;
			top: -2px;

			display: block;

			font-size: 0.5rem;

			color: #866932;
		}

		b {
			display: block;

			font-size: 0.75rem;
			font-weight: 600;

			color: #D8BD9F;
		}
	}

	&__exp-gain {
		position: absolute;
		top: 0;
		right: 4px;

		display: none;
		padding: 0 2px;

		font-weight: 700;
		text-align: right;

		z-index: 3;
	}

	&__exp-gain-nr {
		position: relative;

		font-size: 1rem;

		letter-spacing: -2px;

		color: #FFB83C;

		z-index: 1;
	}

	&__exp-gain-text {
		margin-top: -8px;

		font-size: 0.65em;
		text-transform: uppercase;

		color: #BB8F43;
	}

	&__bl-gain {
		position: absolute;
		bottom: 2px;
		left: 2px;

		display: none;
		padding: 0 2px;

		text-align: right;

		z-index: 3;
	}

	&__bl-gain-nr {
		position: relative;

		font-size: 1rem;

		letter-spacing: -2px;

		color: #ff7869;

		z-index: 1;
	}

	&__bl-gain-text {
		margin-top: -8px;

		font-size: 0.65em;
		text-transform: uppercase;

		color: #bb5d62;
	}


	&.-c-empty {
		background-color: $c-card-empty-background-color;
		border-color: $c-card-empty-border-color;


		&:after {
			content: '';

			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;

			border-style: solid;
			border-color: $c-card-empty-border-inset-color;
			border-width: 4px 2px 2px 4px;
		}


		#{$c}__pos {
			position: absolute;
			top: 4px;
			left: 0;

			width: 46px;
			height: 100%;

			z-index: 1;
			overflow: hidden;


			&:before {
				font-size: 3rem;
				text-shadow: 3px 3px 0 $c-card-text-shadow-color;

				opacity: 0.3;
			}
		}

		// hide most content
		div:not(#{$c}__pos):not(#{$c}__pos-name) {
			display: none !important;
		}
	}

	&.-c-hidden {
		background-color: $c-card-empty-background-color;
		border-color: $c-card-empty-border-color;


		&:after {
			content: '';

			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;

			border-style: solid;
			border-color: $c-card-empty-border-inset-color;
			border-width: 4px 2px 2px 4px;
		}

		// hide most content
		div:not(#{$c}__pos):not(#{$c}__pos-name) {
			display: none !important;
		}
	}


	&.-border-fng {
		border-color: $c-card-fng-border-color;
	}

	&.-border-fnj {
		border-color: $c-card-fng-border-color;
	}

	&.-c-sel {
		border-color: #F9A84C;
	}

	&.-size-xs {
		width: 40px;
		min-width: 40px;
		max-width: 40px;
		min-height: 43px;


		#{$c}__pic {
			width: 34px;
			height: 38px;


			img {
				width: 34px;
				height: 38px;
			}
		}
	}

	&.-size-s {
		/**
		 * --small is the default modifier of c-card.
		 */

		width: 51px;
		min-width: 51px;
		max-width: 51px;
		min-height: 56px;
		padding-bottom: 2px;


		#{$c}__pic {
			margin: 1px 1px -1px 1px;
		}

		#{$c}__details {
			position: relative;

			margin: 2px 2px 0 2px;
		}

		#{$c}__ele {
			@extend .c-ele.-size-s;
		}

		#{$c}__lvl {
			top: 39px;
		}

		#{$c}__lvl-nr {
			font-size: 0.9rem;
		}

		#{$c}__exp {

		}

		#{$c}__pos {
			top: -11px;
			left: 1px;
		}

		#{$c}__pos-name {
			bottom: -3px;
			left: 7px;

			text-align: left;
		}

		#{$c}__data {
			position: relative;

			margin: 2px 2px 0 2px;

			background-color: #251A10;
		}

		#{$c}__chakra,
		#{$c}__seal {
			flex-grow: 1;

			width: auto;
			min-width: 20px;
			margin: 0;

			background: none;


			&:before {
				top: -3px;
				right: auto;

				font-size: 14px;

				color: #675b53;
			}


			span {
				top: 3px;

				padding: 1px;

				font-size: 0.7rem;
				text-align: center;
			}
		}

		#{$c}__chakra {
			order: 1;


			&:before {
				color: #5d5652;
			}
		}

		#{$c}__seal {
			&:before {
				top: -2px;

				color: #604b61;
			}
		}

		#{$c}__dmg {
			top: 1px;

			flex-grow: 1;

			width: auto;
			margin: 0;
			padding: 2px;

			background: none;


			b {
				font-size: 0.7rem;
			}
		}


		&.-c-empty {

			#{$c}__pos {
				top: 0;
				left: 0;
			}

			#{$c}__pos-name {
				bottom: 3px;
				left: 0;

				text-align: center;
			}
		}
	}

	&.-size-m {
		width: 96px;
		min-width: 96px;
		max-width: 96px;
		min-height: 106px;


		#{$c}__pic {
			width: 90px;
			height: 100px;
			margin: 1px 1px -1px 1px;


			&:before {
				content: '';
			}


			img {
				width: 90px;
				height: 100px;
			}
		}

		#{$c}__ele {
			@extend .c-ele.-size-m;
		}

		#{$c}__lvl {
			top: 89px;
		}

		#{$c}__lvl-text {
			font-size: 0.65rem;
		}

		#{$c}__lvl-nr {
			font-size: 1rem;
		}

		#{$c}__details {
			position: relative;

			margin: 2px 2px 0 2px;


			&.-c-filled {
				#{$c}__pos {
					top: -8px;
				}
			}
		}

		#{$c}__exp {
			&.c-exp {
				width: auto;
				height: 7px;
			}
		}

		#{$c}__pos {
			top: -6px;


			&:before {
				font-size: 12px;
			}
		}

		#{$c}__pos-name {
			top: -3px;
			right: auto;
			bottom: auto;
			left: 12px;

			text-align: left;
		}

		#{$c}__data {
			margin: 2px 1px 0 1px;
		}
	}

	&.-size-l {
		width: 186px;
		min-width: 186px;
		max-width: 186px;
		min-height: 206px;


		#{$c}__pic {
			width: 180px;
			height: 200px;
			margin: 1px 1px -1px 1px;


			&:before {
				content: '';

				border: 2px solid rgba(#000, 0.3);
			}


			img {
				width: 180px;
				height: 200px;
			}
		}

		#{$c}__ele {
			@extend .c-ele.-size-l;
		}

		#{$c}__lvl {
			top: 174px;
		}

		#{$c}__lvl-text {
			display: inline;
			margin-right: -2px;

			font-size: 0.8rem;

			color: #E2DED7;
		}

		#{$c}__lvl-nr {
			font-size: 1.5rem;
		}

		#{$c}__exp {
			&.c-exp {
				width: auto;
				height: 12px;
			}
		}

		#{$c}__pos {
			top: -6px;


			&:before {
				font-size: 12px;
			}
		}

		#{$c}__pos-name {
			top: 0;
			right: auto;
			bottom: auto;
			left: 12px;

			text-align: left;
		}

		#{$c}__seal {
			width: 46px;

			background-color: #322214;


			&:before {
				right: 6px;
			}

			span {
				padding-right: 22px;

				letter-spacing: 0;
			}
		}

		#{$c}__chakra {
			width: 50px;


			span {
				padding-left: 4px;

				letter-spacing: 0;
			}
		}
	}

	&.-status-defeated {
		filter: grayscale(1);
	}

	&.-c-exp-gain,
	&.-c-bl-gain {
		&:after {
			content: none;

			position: absolute;
			top: 0;
			left: 0;

			width: 100%;
			height: 100%;

			background: rgba(#482D0F, 0.8);

			transition: all 0.5s linear;

			z-index: 2;
		}
	}

	&.-c-exp-gain {
		&:after {
			content: '';
		}


		#{$c}__exp-gain {
			display: block;
			margin-top: -3px;
		}

		#{$c}__exp-gain-nr {
			font-size: 1.4rem;
		}
	}

	&.-c-exp-gain-active {
		&:after {
			background: none;
		}


		#{$c}__exp-gain {
			display: block;
			margin-top: 0;
		}

		#{$c}__exp-gain-nr {
			font-size: 1rem;
		}

		#{$c}__exp-gain-text {
			margin-top: -5px;

			font-size: 0.55rem;
		}
	}

	&.-c-bl-gain {
		&:after {
			content: '';
		}


		#{$c}__bl-gain {
			display: block;
		}
	}
}
