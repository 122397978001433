.c-page-header {
	position: relative;

	display: flex;
	padding: 30px 5px 0 0;

	text-shadow: 1px 1px 2px #000;


	&__icon {
		flex: 0 0 50px;

		display: flex;
		justify-content: center;

		width: 50px;
		margin-right: 10px;


		i,
		.a-item-icon {
			font-size: 40px;
			line-height: 1.1;

			color: #B0A087;
		}
	}

	&__inner {
		flex: 0 0 300px;

		width: 300px;


		&.-size-wide {
			flex-basis: 500px;

			width: 500px;
		}

		&.-c-clickable {
			cursor: pointer;
		}
	}

	&__category {
		padding-left: 2px;

		font-size: 0.6rem;
		text-transform: uppercase;

		color: #B0A087;
	}

	&__title {
		margin: 0;
		padding: 0;

		font-size: 1.4rem;
		font-weight: 600;
		line-height: 1.1;

		color: #f2e0c0;
	}

	&__desc {
		margin: 0;
		padding: 2px 0 0 0;

		font-size: 0.8rem;
		line-height: 1.1;

		color: #e1c292;


		a {
			text-decoration: underline;
			color: #F1B26F;
		}
	}

	&__desc-details {
		margin: 0;
		padding-top: 10px;

		font-size: 0.7rem;
		line-height: 1.1;

		color: #CCA466;
	}

	&__side {
		position: relative;

		flex: 1 0 auto;
	}

	&__navigation {
		position: absolute;
		top: 25px;
		right: 25px;

		font-size: 40px;
		text-align: right;


		i {
			padding: 3px;

			color: #B0A087;

			cursor: pointer;


			&:hover {
				color: #f2e0c0;
			}
		}
	}



	@include for-desktop-up {

		// wide size layout
		.l-content-container.-size-wide & {
			margin-right: 212px;
		}
	}
}
