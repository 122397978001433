.c-exp {
	$c: &;

	position: relative;

	width: 40px;
	height: 12px;

	background: linear-gradient(to right, $c-exp-background-gradient-from-color, $c-exp-background-gradient-to-color);


	&:before {
		content: "";

		position: absolute;
		top: 0;
		right: 0;

		display: block;
		width: 1px;
		height: 100%;

		background-color: $c-exp-pseudo-border-right-color;
	}

	&:after {
		content: "";

		position: absolute;
		top: 0;
		left: 0;

		display: block;
		width: 100%;
		height: 1px;

		background: linear-gradient(to right, $c-exp-pseudo-border-top-gradient-from-color, $c-exp-pseudo-border-top-gradient-to-color);
	}


	&__fill {
		position: relative;

		width: 10%;
		height: 100%;

		background: linear-gradient(to right, $c-exp-fill-background-gradient-from-color, $c-exp-fill-background-gradient-to-color);


		&:before {
			content: "";

			position: absolute;
			top: 0;
			right: 0;

			display: block;
			width: 1px;
			height: 100%;

			background-color: $c-exp-fill-pseudo-border-right-color;
		}

		&:after {
			content: "";

			position: absolute;
			top: 0;
			left: 0;

			display: block;
			width: 100%;
			height: 1px;

			background: linear-gradient(to right, $c-exp-fill-pseudo-border-top-gradient-from-color, $c-exp-fill-pseudo-border-top-gradient-to-color);

			z-index: 1;
		}
	}

	&__exp-left {
		position: absolute;
		top: 0;
		right: 3px;

		font-size: 0.6rem;
		text-align: right;
		text-shadow: 1px 1px 1px $shadow-color-strongest;

		color: $c-exp-left-color;
	}


	&.-type-bloodline {
		background: linear-gradient(to right, $c-exp-bloodline-background-gradient-from-color, $c-exp-bloodline-background-gradient-to-color);


		&:before {
			background-color: $c-exp-bloodline-pseudo-border-right-color;
		}

		&:after {
			background: linear-gradient(to right, $c-exp-bloodline-pseudo-border-top-gradient-from-color, $c-exp-bloodline-pseudo-border-top-gradient-to-color);
		}


		#{$c}__fill {
			background: linear-gradient(to right, $c-exp-bloodline-fill-background-gradient-from-color, $c-exp-bloodline-fill-background-gradient-to-color);

			&:before {
				background-color: $c-exp-bloodline-fill-pseudo-border-right-color;
			}

			&:after {
				background: linear-gradient(to right, $c-exp-bloodline-fill-pseudo-border-top-gradient-from-color, $c-exp-bloodline-fill-pseudo-border-top-gradient-to-color);
			}
		}

		#{$c}__exp-left {
			color: $c-exp-bloodline-left-color;
		}
	}


	&.-size-s {
		height: 7px;
	}

	&.-size-l {
		height: 12px;
	}
}