.c-ninja-box {
	$c: &;

	@extend %c-box;


	&__details {
		flex: 1 0 auto;

		position: relative;

		display: flex;

		min-width: 0;
		padding: 8px;
	}


	&__card {
		flex: 0 0 auto;

		min-width: 122px;
		margin: 0 12px 2px 0;


		// module
		&.m-card-container {

			// component
			.c-card {
				align-self: flex-start;
			}
		}
	}


	&__info {
		// .c-ninja-box-info
	}

	&__requirements {
		flex: 0 0 110px;

		display: flex;
		align-items: flex-end;
		justify-content: flex-end;

		width: 110px;
		margin: 0 5px 10px 5px;
	}

	&__cost {
		position: relative;
		right: -15px;

		display: flex;
		flex-flow: column nowrap;
		align-items: center;


		// component
		.c-resource {
			width: 85px;
			min-width: 85px;
			margin: 0 0 -2px 0;


			// component
			.c-notification {
				position: absolute;
				top: -5px;
				right: 75px;

				// ie 11 somewhat fix
				min-width: 50px;


				span {
					white-space: nowrap;
				}
			}
		}

		// component
		.c-button {
			justify-content: flex-start;

			width: 110px;
			min-width: 110px;
			height: 35px;


			span {
				flex: 1 0 0%;

				margin-left: -20px;

				text-align: center;
			}
		}

		// component
		.c-notification {
			position: absolute;
			top: -7px;
			right: 85px;

			// ie 11 somewhat fix
			min-width: 50px;

			z-index: 1;


			span {
				white-space: nowrap;
			}
		}
	}

	&__stats {
		flex: 0 0 200px;

		display: flex;
		justify-content: flex-end;

		width: 200px;


		// component
		.c-stats-list {
			width: 150px;
			margin: 10px;
		}
	}


	// component
	&__rank {
		&.c-rank {
			position: absolute;
			top: -6px;
			right: 3px;
		}
	}
}


/**
 * Info section.
 */
.c-ninja-box-info {
	position: relative;

	flex: 1 1 auto;

	display: flex;
	flex-flow: row wrap;

	min-width: 0;
	margin: 0 5px;

	text-shadow: 1px 1px 1px #000;


	&__top {
		position: relative;

		flex: 1 1 auto;

		width: 100%;
		min-width: 0;

		line-height: 1.2;


		// component
		.c-underline {
			margin-top: -1px;
		}
	}

	&__name {
		position: relative;

		flex: 0 1 auto;

		min-width: 0;

		font-size: 1.1rem;
		text-overflow: ellipsis;
		white-space: nowrap;

		color: #FFB56B;

		overflow: hidden;

		z-index: 1;
	}

	&__desc {
		margin-top: 3px;

		font-size: 0.7rem;
		line-height: 1.0;

		color: #D6B186;
	}

	&__bot {
		flex: 0 1 auto;

		display: flex;
		flex-flow: column nowrap;
		justify-content: flex-end;

		width: 80%;
		margin-top: 10px;
	}

	&__combos {
		// .c-ninja-box-combos
	}
}


/**
 * Combos section.
 */
.c-ninja-box-combos {


	&__title {
		font-size: 0.6rem;
		line-height: 1.2;
		text-transform: uppercase;
		text-shadow: 1px 1px 1px #000;

		color: #79B87E;
	}

	&__list {
		display: flex;
		flex-flow: row wrap;

		margin: 6px 1px 2px 1px;


		// component
		.c-item {
			margin: 0 5px 5px 0;
		}
	}


	// component
	.c-underline {
		width: auto;
	}
}