.c-effect {
	$c: &;

	position: relative;

	display: flex;
	flex-flow: row nowrap;
	align-items: center;

	height: 23px;
	padding: 0 4px 0 2px;

	border: 1px solid #000;
	border-radius: 3px;

	background-color: #000;


	&__icon {
		width: 30px;

		font-size: 24px;
		text-align: center;
		text-shadow: 0 0 1px #000;


		&:before {
			@extend %font-nm;
		}
	}

	&__text {
		padding: 0 2px 0 2px;

		font-size: 0.7rem;


		b {
			font-size: 0.8rem;
		}
	}

	&__pass-icon {
		padding: 0 3px;

		font-size: 16px;
		text-align: center;
		text-shadow: 0 0 1px #000;


		&:before {
			@extend %font-nm;
		}
	}

	&__box {
		position: relative;

		margin-left: 4px;
		padding: 0 3px;

		border: 1px solid #5c7794;
		border-radius: 3px;

		font-size: 0.5rem;

		color: #84befe;


		b {
			margin-left: 1px;

			font-size: 0.6rem;

			color: #b2d7ff;
		}
	}


	&.-type-focus {
		#{$c}__icon {
			position: relative;
			top: -2px;

			color: #ffa362;


			&:before {
				content: '\e856';
			}
		}

		#{$c}__text {
			min-width: 28px;

			color: #deb191;


			b {
				color: #ffa362;
			}
		}
	}

	&.-type-poison {
		#{$c}__icon {
			color: #8cfe84;

			&:before {
				content: '\e93d';
			}
		}

		#{$c}__text {
			color: #8a9484;


			b {
				color: #8cfe84;
			}
		}
	}

	&.-type-genjutsu {
		#{$c}__cost {
			width: 25px;

			font-size: 0.7rem;
			text-align: right;

			color: #84befe;
		}

		#{$c}__icon {
			position: relative;
			top: -2px;

			width: 32px;

			font-size: 26px;

			color: #84befe;


			&:before {
				content: '\e800';
			}
		}

		#{$c}__text {
			padding: 0 5px 0 2px;

			text-overflow: ellipsis;
			white-space: nowrap;

			color: #CACACA;

			overflow: hidden;


			b {
				font-size: 0.7rem;

				color: #72E4FF;
			}
		}

		#{$c}__pass-icon {
			color: #92b0d2;
		}
	}

	&.-type-proc {
		text-shadow: 1px 1px 1px #030425;


		#{$c}__icon {
			border-color: #417ab7;
		}

		#{$c}__pass-icon {
			text-shadow: 1px 1px 0 #000;
		}
	}

	&.-proc-2 {
		background: linear-gradient(to right, #000 40%, #0a386d);
	}

	&.-proc-3 {
		background: linear-gradient(to right, #000 40%, #0f58ab);
	}

	&.-proc-4 {
		background: linear-gradient(to right, #000 40%, #1174e6);
	}


	&.-type-pass {
		#{$c}__cost {
			color: #a6a0fb;
		}

		#{$c}__icon {
			color: #9a93ff;
		}

		#{$c}__text {
			b {
				color: #bcb9fb;
			}
		}

		#{$c}__box {
			border-color: #745c94;

			color: #9a93ff;


			b {
				color: #c5b2ff;
			}
		}

		#{$c}__pass-icon {
			color: #837cbb;
		}


		&.-type-proc {
			text-shadow: 1px 1px 1px #100325;


			#{$c}__pass-icon {
				color: #9992d2;
			}
		}

		&.-proc-2 {
			background: linear-gradient(to right, #000 40%, #3b2473);
		}

		&.-proc-3 {
			background: linear-gradient(to right, #000 40%, #53359c);
		}

		&.-proc-4 {
			background: linear-gradient(to right, #000 40%, #6638dc);
		}
	}

	&.-type-party {
		background: linear-gradient(to right, #000 40%, #6d2121);


		#{$c}__cost {
			color: #fe8484;
		}

		#{$c}__icon {
			color: #fe8484;
		}

		#{$c}__text {
			b {
				color: #ff7272;
			}
		}

		#{$c}__box {
			border-color: #945c5c;

			color: #fe8484;


			b {
				color: #ffb2b2;
			}
		}
	}
}
