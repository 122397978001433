.c-item-details {
	$c: &;

	position: relative;

	border: 2px solid #795936;
	border-radius: 2px;

	background-color: #3f2a19;

	cursor: pointer;


	&:before {
		content: '';

		position: absolute;
		top: -3px;
		right: -4px;
		bottom: -4px;
		left: -3px;

		border-style: solid;
		border-color: #050302;
		border-width: 1px 2px 2px 1px;
		border-radius: 4px;
	}

	&:after {
		content: '';

		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;

		border: 2px solid #2b1d11;
	}


	&__stats {
		display: flex;
		flex-flow: column nowrap;

		padding: 5px;


		span {
			width: 100%;
			padding: 0 1px 0 2px;

			font-size: 0.7rem;
			line-height: 1.3;
			text-align: left;
			text-shadow: 1px 1px 0 #000;

			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;

			color: #a68b69;
		}

		b {
			font-size: 0.7rem;

			color: #d2b38b;
		}
	}

	&__genjutsu {
		position: relative;
		bottom: -4px;
		right: -4px;

		margin-top: auto;

		text-align: right;


		i {
			position: relative;

			margin-left: -5px;
			padding: 3px;

			font-size: 18px;
			text-shadow: 1px 1px 1px #000;

			color: #71b1ea;

			cursor: pointer;

			z-index: 1;
		}
	}
}