.c-underline {
	position: relative;

	width: 100%;
	height: 1px;

	background: linear-gradient(to right, #9F724F, rgba(84,60,42,0));


	&:after {
		content: '';

		position: absolute;
		top: 1px;
		left: 1px;

		width: 100%;
		height: 1px;

		background: linear-gradient(to right, #000, rgba(84,60,42,0));
	}


	&.-color-greyed {
		background: linear-gradient(to right, #846C57, rgba(84,60,42,0));
	}

	&.-color-combos {
		background: linear-gradient(to right, #637A64, rgba(84,60,42,0));
	}
}