.c-user-box {
	$c: &;

	position: relative;

	display: flex;

	border: 2px solid #795936;
	border-radius: 2px;

	background-color: #3f2a19;

	cursor: pointer;


	&:before {
		content: '';

		position: absolute;
		top: -3px;
		left: -4px;
		right: -4px;
		bottom: -5px;

		border-style: solid;
		border-color: #000;
		border-width: 1px 2px 3px 2px;
		border-radius: 4px;
	}

	&:after {
		content: '';

		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;

		border: 2px solid #2b1d11;
	}

	&__avatar-container {
		position: relative;
	}

	&__avatar {
		position: relative;

		flex: 0 0 auto;

		width: 45px;
		height: 50px;

		overflow: hidden;


		&:before {
			content: '';

			position: absolute;
			top: 1px;
			left: 1px;
			right: 1px;
			bottom: 1px;

			border: 1px solid rgba(#000, 0.5);

			z-index: 1;
		}

		&:after {
			content: '';

			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;

			border: 1px solid #2c1d10;

			z-index: 1;
		}


		img {
			width: 45px;
			height: 50px;
		}


		&.-align-center {
			display: flex;
			align-items: center;
			justify-content: center;
		}

		&.-c-borderless {
			&:before,
			&:after {
				content: none;
			}
		}
	}

	&__emblem {
		font-size: 30px;
		color: #B0A087;
		text-shadow: 1px 1px 0 #000;
	}

	&__inner {
		flex: 1 1 0%;

		display: flex;
		flex-flow: column nowrap;

		min-width: 0;
		margin-left: -1px;
	}

	&__top {
		display: flex;
		align-items: center;

		height: 23px;
		margin-right: 2px;
	}

	&__user {
		flex: 1 1 auto;

		padding: 0 2px 0 10px;

		font-size: 0.7rem;
		text-overflow: ellipsis;
		white-space: nowrap;

		color: #DEC4A7;

		overflow: hidden;
	}

	&__icons {
		flex: 0 1 auto;

		display: flex;
		align-items: center;

		margin: 2px 4px 0 3px;


		i {
			padding: 2px 0;

			font-size: 12px;

			color: #80664b;
		}


		.icon-dharma {
			color: #ca7c6f;
		}
	}

	&__team {
		flex: 1 0 auto;

		margin-right: 2px;

		background: linear-gradient(to right, #311F13, rgba(#4a3323, 0));
	}


	&.-c-avatar-fade {
		#{$c}__avatar {
			&:after {
				background-color: rgba(#2c1d10, 0.8);
			}
		}
	}

	&.-c-avatar-only {
		#{$c}__inner {
			display: none;
		}
	}

	&.-border-hidden {
		border: 0;
		border-radius: 0;


		&:before {
			content: none;
		}

		&:after {
			content: none;
		}
	}

	&.-size-xs {
		height: 25px;


		#{$c}__rating {
			position: relative;
			top: 1px;
		}

		#{$c}__avatar {
			position: absolute;

			height: 21px;


			img {
				position: relative;
				top: -10px;
			}


			&.-c-borderless {
				background-color: #321f13;
			}
		}

		#{$c}__inner {
			margin-left: 45px;
		}


		&:hover {
			z-index: 2;


			#{$c}__avatar {
				top: -10px;

				height: 50px;

				z-index: 2;


				img {
					top: auto;
				}
			}
		}


		&.-border-hidden {
			height: 25px;


			#{$c}__avatar {
				height: 25px;
			}


			&:hover {
				#{$c}__avatar {
					height: 50px;
				}
			}
		}
	}

	&.-c-reverse {

		#{$c}__avatar {
			right: 0;
		}

		#{$c}__team {
			flex-flow: row-reverse;

			margin-right: 0;
			margin-left: 2px;

			background: linear-gradient(to left, #311F13, rgba(#4a3323, 0));
		}


		// page
		.c-user-box-team {
			&__rating {
				text-align: left;
			}

			&__pow {
				justify-content: flex-start;
			}

			&__name {
				text-align: right;
			}
		}


		&.-size-xs {
			#{$c}__inner {
				margin-right: 45px;
				margin-left: 0;
			}
		}
	}


	.a-sidebar & {
		background: none;


		&__avatar {
			&:before {
				border-color: rgba(#E0D5AC, 0.25);
			}

			&:after {
				border-color: #E0D5AC;
			}
		}

		&__user {
			font-weight: 700;
			text-shadow: 1px 1px 1px #F9F2D8;

			color: #674520;
		}

		&__team {
			background: linear-gradient(to right, #DCCA97, rgba(#bdaa79, 0));

		}


		&.-border-hidden {
			#{$c}__team {
				&:before,
				&:after {
					content: '';

					position: absolute;
					right: 0;
					bottom: -1px;
					left: -45px;

					height: 1px;

					background: linear-gradient(to right, #AB9351 50%, rgba(#bdaa79, 0));
				}

				&:after {
					bottom: -2px;

					background: linear-gradient(to right, #AF9F71, rgba(#bdaa79, 0));
				}
			}
		}
	}
}



/**
 * Team section.
 */
.c-user-box-team {
	position: relative;

	display: flex;
	align-items: center;


	&__rating {
		position: relative;
		top: 1px;

		width: 33px;
		min-width: 33px;

		font-size: 0.575rem;
		text-align: right;
		text-shadow: 1px 1px 1px #000;

		color: #BD9D78;


		&.-c-pos {
			text-align: center;
		}

		&.-c-tag {
			width: 25px;
			min-width: 25px;
			padding-right: 2px;
		}
	}

	&__pow {
		position: relative;
		top: 1px;

		display: flex;
		justify-content: flex-end;

		width: 33px;
		min-width: 33px;


		// component
		.c-pow {

		}
	}

	&__rank {
		width: 30px;
		min-width: 30px;
	}

	&__name {
		flex: 1 1 0%;

		padding: 0 1px;

		font-size: 0.7rem;
		font-weight: 600;
		text-align: left;
		text-overflow: ellipsis;
		white-space: nowrap;
		text-shadow: 1px 1px 1px #000;

		color: #EDC896;

		overflow: hidden;


		a {
			padding: 0 1px;

			text-decoration: underline;

			color: #EDC896;
		}
	}


	.a-sidebar & {
		&__rating {
			font-size: 0.6rem;
			font-weight: 700;
			text-shadow: 1px 1px 1px #F9F5EB;

			color: #69451D;
		}

		&__name {
			font-weight: 700;
			text-shadow: 1px 1px 1px #F9F5EB;

			color: #3E2903;
		}
	}
}
