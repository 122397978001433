.c-stats-list {
	position: relative;

	padding-bottom: 5px;

	border-style: solid;
	border-width: 2px 0 2px 0;
	border-color: #6C4E3A;
	border-radius: 2px;

	background-color: rgba(#231509, 0.6);


	&:before {
		content: '';

		position: absolute;
		top: -4px;
		right: 0;
		bottom: -4px;
		left: 0;

		border-style: solid;
		border-color: rgba(#000, 0.25);
		border-width: 2px 0 2px 0;
		border-radius: 4px;
	}


	&__header {
		margin-bottom: 3px;
		padding: 0 2px 0 0;

		border-bottom: 1px solid #543B2B;

		background-color: #2A1A0F;

		text-align: right;


		span {
			min-width: 30px;
			padding: 0 3px;

			font-size: 0.575rem;
			text-shadow: 1px 1px 1px #000;

			color: #A27B60;


			&.-c-total {
				display: inline-block;

				width: 45px;
				padding-right: 5px;
			}
		}
	}

	&__stat {
		// .c-stats-list-stat
	}
}

.c-stats-list-stat {
	position: relative;

	display: flex;
	align-items: center;

	margin: 0 6px 0 5px;
	padding: 1px 0;

	border-bottom: 1px solid #523C26;

	text-shadow: 1px 1px 1px #000;


	&__name {
		flex: 1 1 auto;

		padding: 0 5px 0 1px;

		font-size: 0.7rem;
		text-align: right;
		text-overflow: ellipsis;
		white-space: nowrap;

		color: #D6B186;

		overflow: hidden;
	}

	&__base {
		flex: 0 0 20px;

		width: 20px;
		margin: 0 2px;

		font-size: 0.8rem;
		text-align: center;

		color: #F7D7B4;
	}

	&__growth {
		flex: 0 0 20px;

		width: 20px;
		margin: 0 2px;

		font-size: 0.8rem;

		color: #F7D7B4;


		&.-c-zero {
			color: #775A3B
		}
	}

	&__total {
		flex: 0 0 50px;

		width: 50px;
		margin: 0 2px;

		font-size: 0.9rem;
		text-align: right;

		color: #FFB56B;


		&.-c-zero {
			color: #775A3B
		}
	}


	&.-c-genjutsu {
		justify-content: flex-end;

		padding-top: 3px;
		padding-right: 3px;


		i {
			margin-left: -5px;
			padding: 1px 3px;

			font-size: 18px;
			text-shadow: 1px 1px 1px #000;

			color: #71b1ea;

			cursor: pointer;
		}
	}


	&:last-child {
		border-bottom: 0;
	}
}