.c-pow {
	position: relative;

	display: flex;
	align-items: center;
	justify-content: flex-end;

	height: 22px;

	font-size: 0.6rem;
	text-shadow: 1px 1px 1px #000;

	color: #A68767;


	span {
		position: relative;
	}


	&:before {
		@extend %font-nm;

		content: none;

		position: absolute;
		right: 0;

		font-size: 8px;
	}


	&.-direction-down {

		span {
			bottom: 2px;
		}


		&:before {
			content: '\e847';

			bottom: 0;
		}
	}

	&.-direction-up {
		span {
			top: 2px;
		}


		&:before {
			content: '\e846';

			top: 0;
		}
	}

	&.-down-1 {
		color: #bf7e6a;
	}

	&.-down-2 {
		color: #bf6a6a;
	}

	&.-down-3 {
		color: #bf6060;
	}
}