.c-mission-box {
	$c: &;

	@extend %c-box;


	&__path {
		position: absolute;
		top: -5px;
		bottom: -3px;
		left: -9px;

		width: 5px;

		border-left: 2px solid #000;
		border-bottom: 2px solid #000;

		background-color: #C06C40;


		span {
			position: absolute;
			bottom: -90px;
			left: -12px;

			width: 150px;
			transform: rotate(-90deg);
			transform-origin: left top 0;

			font-size: 0.6rem;
			text-align: center;
			white-space: nowrap;
			letter-spacing: 1px;
			text-transform: uppercase;
			text-shadow: 1px 1px 2px #000;

			color: #C06C40;
		}


		&.-path-first {
			top: -2px;
			bottom: -1px;

			border-bottom: 0;
		}

		&.-status-complete {
			background-color: #834A2C;


			span {
				color: #834A2C;
			}
		}
	}

	&__series {
		position: absolute;
		top: -10px;
		right: 150px;

		width: 13px;
		height: 8px;

		background: #543C2A;
		background: linear-gradient(to bottom, #543C2A, #765A45);

		z-index: 3;


		&:after {
			content: '';

			position: absolute;
			top: 8px;
			left: -7px;

			width: 0;
			height: 0;

			border-left: 14px solid transparent;
			border-right: 14px solid transparent;
			border-top: 14px solid #765A45;
		}
	}

	&__badges {
		position: absolute;
		top: 0;
		right: 120px;

		display: flex;

		cursor: pointer;

		z-index: 3;
	}

	&__badge {
		position: relative;

		width: 19px;

		text-align: center;


		&:after {
			content: '';

			position: absolute;
			top: 0;
			right: 0;
			left: 0;

			width: 12px;
			height: 15px;

			margin: 0 auto;

			border-radius: 0 0 3px 3px;

			background-color: #755a45;
		}


		i {
			position: relative;
			top: -2px;

			font-size: 20px;
			text-shadow: 1px 1px 1px #000;

			color: #b5947a;

			z-index: 1;
		}
	}

	&__details {
		flex: 1 0 auto;

		position: relative;

		display: flex;

		padding: 8px;
	}

	&__health {
		margin: 10px 140px 10px 20px;
	}

	&__pic {
		flex: 0 0 51px;

		width: 51px;
		margin: 0 10px 2px 0;


		// component
		.c-card {

			&__icon {
				position: absolute;
				right: -5px;
				bottom: -7px;

				font-size: 14px;
				text-shadow: -1px -1px 1px #000,
							1px 1px 1px #000;

				color: #A98258;

				z-index: 1;
			}

			&__overlay {
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;

				display: flex;
				align-items: center;
				justify-content: center;

				font-size: 20px;
				text-shadow: -1px -1px 1px #000,
							1px 1px 1px #000;

				color: #A98258;

				background-color: rgba(#2a1d11, 0.5);

				z-index: 1;
			}
		}
	}

	&__icons {
		position: absolute;
		right: -5px;
		bottom: -7px;

		display: flex;
		align-items: flex-end;
		justify-content: flex-end;

		line-height: 1;

		z-index: 1;


		i {
			margin-left: -1px;

			font-size: 14px;
			text-shadow: -1px -1px 1px #000,
						1px 1px 1px #000;

			color: #A98258;


			b {
				position: absolute;

				font-size: 12px;
				padding-right: 1px;
				letter-spacing: -1px;

				color: #000;
			}


			&.-c-filled {
				display: flex;
				align-items: center;
				justify-content: center;

				font-size: 18px;
			}

			&.-type-order {
				color: #a95858;


				b {
					text-shadow: 1px 1px 1px #daa0a0;
				}
			}
		}
	}

	&__pic-locked {
		position: relative;

		display: flex;
		align-items: center;
		justify-content: center;

		width: 51px;
		height: 56px;

		border-radius: 2px;

		background-color: #452D1C;


		i {
			font-size: 30px;
			text-shadow: 1px 1px 1px #000,
						-1px -1px 0px #B29D82;

			color: #6B5438;
		}
	}

	&__info {
		// .c-mission-box-info
	}

	&__rewards {
		flex: 0 0 130px;

		display: flex;
		flex-flow: row wrap;
		justify-content: center;

		width: 130px;
		margin: 0 5px 0 5px;


		// component
		.c-item {
			display: flex;

			width: 120px;
			margin: 1px;


			&.-type-material {
				width: auto;
			}

			&.-width-drop {
				width: 59px;
			}
		}
	}

	&__resources {
		width: 100%;
		margin-bottom: 2px;

		text-align: center;


		// component
		.c-resource {
			display: inline-flex;

			min-width: 50px;
			margin: 0 2px 4px 2px;
		}
	}

	&__requirements {
		flex: 0 0 150px;

		width: 150px;
		margin: 0 5px 0 5px;
	}

	&__cost {
		display: flex;
		flex-flow: column nowrap;
		align-items: flex-end;


		// component
		.c-resource {
			width: 70px;
			min-width: 70px;
			margin: 0 10px -2px 0;
		}

		// component
		.c-button {

		}
	}

	&__conditions {
		margin-top: 5px;

		text-align: right;


		span {
			display: block;
			padding: 3px 0;

			border-top: 1px solid #654A35;

			font-size: 0.7rem;
			line-height: 1.1;
			text-shadow: 1px 1px 1px #000;

			color: #D6B186;
		}

		b {
			color: #FFB56B;


			&.-type-chakra {
				color: #ccc;
			}
		}
	}

	&__friends {
		flex: 1 0 100%;

		display: flex;
	}


	&.-left-0 {
		margin-left: 0;
	}

	&.-left-1 {
		margin-left: 6px;
	}

	&.-left-2 {
		margin-left: 12px;
	}

	&.-left-3 {
		margin-left: 18px;
	}

	&.-left-4 {
		margin-left: 24px;
	}

	&.-left-5 {
		margin-left: 30px;
	}

	&.-status-locked {
		&:after {
			content: '';

			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;

			background-color: rgba(#543C2A, 0.8);

			z-index: 2;
		}


		#{$c}__pic {
			z-index: 3;
		}

		#{$c}__rewards {
			z-index: 3;
		}

		&.-c-hide.-c-disabled {
			#{$c}__rewards {
				z-index: 2;
			}
		}
	}

	&.-c-hide-opacity {
		opacity: 0.25;
	}

	&.-c-hide {
		#{$c}__pic {
			z-index: auto;
		}

		#{$c}__rewards {
			z-index: 3;
		}
	}

	&.-c-disabled {
		&:after {
			background-color: rgba(#543C2A, 0.3);
		}
	}

	&.-color-hardpath {
		border-color: #78517d #4d2754 #4d2754 #78517d;;
		background-color: #5e3767;


		// component
		.c-box__bg {
			&:before {
				background: url('#{$gfx-path}/themes/#{$theme}/shared/row_hardpath_bg.jpg') top right repeat-y;
			}

			&:after {
				left: auto;

				width: 520px;

				background: linear-gradient(to left, rgba(#482A54, 0.7), #5e3767);
			}
		}


		#{$c}__pic {

			// component
			.c-card {
				border-color: #9566b9;
			}
		}

		#{$c}__pic-locked {
			background-color: #4b275d;


			i {
				color: #61386b;
				text-shadow: 1px 1px 1px #000,
							-1px -1px 0px #a082b2;
			}
		}

		#{$c}__icons {
			i {
				color: #a970c1;
			}
		}

		#{$c}__cost {

			// component
			.c-resource {
				border-color: #6c5082;
			}
		}

		#{$c}__badge {
			&:after {
				background-color: #78517d;
			}


			i {
				color: #cda1d2;
			}
		}


		// page
		.c-mission-box-info {

			&__title {
				color: #e493ff;
			}

			&__desc {
				color: #c180d6;
			}


			// component
			.c-underline {
				background: linear-gradient(to right, #c074ce, rgba(89,52,101, 0));
			}
		}

		// page
		.c-mission-box-friends {
			background-color: #53305a;


			&__title {
				color: #9a73af;
			}

			&__icon {
				color: #8b669e;
			}


			// component
			.c-user-box-team {
				background: linear-gradient(to right, #392046, rgba(61,35,74, 0));
			}
		}


		&.-status-locked {
			color: #fff;


			&:after {
				background-color: rgba(86, 52, 95, 0.8)
			}
		}
	}
}


/**
 * Info section.
 */
.c-mission-box-info {
	position: relative;

	flex: 1 1 auto;

	min-width: 0;
	margin: 0 5px;

	text-shadow: 1px 1px 1px #000;


	&__top {
		display: flex;
		align-items: flex-end;

		position: relative;

		margin-top: -2px;

		line-height: 1.4;

		z-index: 1;
	}

	&__title {
		flex: 0 1 auto;

		min-width: 0;

		font-size: 0.95rem;
		text-overflow: ellipsis;
		white-space: nowrap;

		color: #FFB56B;

		overflow: hidden;
	}

	&__desc {
		margin-top: 3px;

		font-size: 0.7rem;
		line-height: 1.0;

		color: #D6B186;


		b {
			color: #F1993C;
		}

		i {
			font-size: 16px;
			vertical-align: text-top;

			cursor: pointer;
		}
	}

	&__progress {

		// component
		&.c-bar {
			width: auto;
			margin: 8px 40px 1px -1px;
		}
	}


	// component {
	.c-difficulty-estimate {
		flex: 1 0 auto;

		top: -1px;

		display: inline-flex;
		margin-left: 10px;
	}

	// component
	.c-underline {
		max-width: 300px;
		margin-top: -3px;
	}
}


/**
 * Friends progress section.
 */
.c-mission-box-friends {
	position: relative;

	flex: 1 0 auto;

	display: flex;
	min-height: 43px;

	background-color: #4c3421;


	&__title {
		flex: 0 0 60px;

		display: flex;
		justify-content: flex-end;

		width: 60px;
		margin-left: 2px;
		padding: 12px 2px 2px 2px;

		font-size: 0.6rem;
		line-height: 1;
		text-align: right;
		text-transform: uppercase;
		text-shadow: 1px 1px 1px #000;

		color: #92775b;
	}

	&__icon {
		flex: 0 0 32px;

		display: flex;
		justify-content: center;

		width: 32px;
		padding: 10px 2px 2px 2px;

		font-size: 22px;
		line-height: 1;
		text-align: right;
		text-shadow: 1px 1px 1px #000;

		color: #846b50;
	}

	&__list {
		flex: 1 0 0%;

		display: flex;
		flex-flow: row wrap;

		min-width: 0;
		padding: 10px 10px 6px 10px;


		// component
		.c-user-box {
			width: 180px;
			margin: 0 2px 2px 0;

			background-color: transparent;
		}
	}
}
