.c-sb-list-header {
	position: relative;

	display: flex;
	flex-flow: column nowrap;

	background-color: #E0D5AC;


	&:before {
		content: '';

		position: absolute;
		top: -27px;
		right: 0;
		left: -0;

		width: 231px;
		height: 35px;
		margin: 0 auto;

		background: url('#{$gfx-path}/themes/#{$theme}/shared/challenges_head_bg_top.png') center center no-repeat;
	}

	&:after {
		content: '';

		position: absolute;
		right: 0;
		bottom: -3px;
		left: 0;

		width: 190px;
		height: 8px;
		margin: 0 auto;

		background: url('#{$gfx-path}/themes/#{$theme}/shared/challenges_head_bg_bot.png') center center no-repeat;

		z-index: 1;
	}

	&__title {
		padding: 10px 16px 10px 20px;

		font-family: georgia, serif;
		font-size: 0.7rem;
		line-height: 1.2;
		text-align: center;
		text-shadow: 1px 1px 0 #F5EFD2;

		color: #7c603b;
	}

	&__list-header {

		span {

		}
	}
}