.c-more-button {
	$c: &;

	position: relative;

	display: inline-flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;

	background-color: #322214;
	border-radius: 4px;

	cursor: pointer;


	&__icon {
		flex: 0 0 40px;
		align-self: flex-start;

		width: 40px;

		font-size: 32px;
		line-height: 1;
		text-align: center;
		text-shadow: 1px 1px 1px #000;

		color: #CE8F7B;
	}

	&__text {
		flex: 1 0 auto;

		padding: 5px 10px 6px 5px;

		font-size: 0.6rem;
		line-height: 1;
		text-shadow: 1px 1px 1px #000;

		color: #A78C7C;


		b {
			font-size: 0.7rem;
			line-height: 1.1;
			text-decoration: underline;

			color: #CE8F7B;
		}

		em {
			font-weight: 700;

			color: #c57a62;
		}
	}


	&:hover {
		background-color: #291B0E;
	}


	&.-icon-lw {
		#{$c}__icon {
			margin-top: -5px;
		}
	}

	&.-icon-guide {
	}

	&.-icon-discord {
		#{$c}__icon {
			margin-top: 4px;

			font-size: 24px;
		}
	}

	&.-icon-register {
		#{$c}__icon {
			margin-top: 4px;

			font-size: 24px;
		}
	}


	&.-c-sidebar {
		background-color: #907744;

		border: 2px solid;
		border-color: #775b39 #decd9e #decd9e #775b39;


		#{$c}__icon {
			color: #e6cb97;
		}

		#{$c}__text {
			color: #ecd9b4;

			b {
				color: #ffd483;
			}
		}


		&:hover {
			background-color: #866844;
		}
	}

	&.-c-expand {
		background: none;
		border: none;


		#{$c}__icon {
			align-self: center;
			flex-basis: 22px;

			width: 22px;
			margin-top: 0;

			font-size: 14px;
			text-align: right;

			color: #e6cb97;
		}

		#{$c}__text {

		}


		&:hover {
			background: none;

			#{$c}__icon,
			#{$c}__text {
				color: #f3e7cf;
			}
		}
	}

	&.-c-discord {
		background-color: #6d519a;
		border-color: #442c6b #decd9e #decd9e #442c6b;


		#{$c}__icon {
			color: #b997e6;
		}

		#{$c}__text {
			color: #cfc0ec;

			b {
				color: #ddb8ff;
			}
		}


		&:hover {
			background-color: #563c82;
		}
	}

	&.-c-important {
		background-color: #9a5151;
		border-color: #6b2c2c #decd9e #decd9e #6b2c2c;


		#{$c}__icon {
			color: #e69797;
		}

		#{$c}__text {
			color: #ecc0c0;

			b {
				color: #ffb8b8;
			}
		}


		&:hover {
			background-color: #823c3c;
		}
	}

	&.-c-shadow {
		border: 2px solid;
		border-color: #2b1b09 #000 #000 #2b1b09;
	}
}