/*
 * General
 */

// theme
$theme: 'akatsuki-dawn';




/*
 * Misc
 */
$shadow-color-primary: $color-seal-brown;
$shadow-color-strongest: $color-black;





/*
 * Layout
 */

// structure
$test-color: $color-black;

$body-background-color: $color-black;
$body-color: $color-white;


// header
$header-background-color: $color-morocco-brown;
$header-after-background-color: $color-van-cleef;
$header-after-background-color-beta: $color-persian-red;
$header-after-border-top-color: $color-black;
$header-before-background-color: $color-black;

// header user
$header-user-border-color: $color-seal-brown-2;
$header-user-before-background-color: $color-clinker;

$header-user-avatar-border-top-color: $color-woodburn;
$header-user-avatar-border-right-color: $color-cocoa-brown-2;
$header-user-avatar-border-bottom-color: $color-woodburn;
$header-user-avatar-border-left-color: $color-kabul;

$header-user-name-background-color: $color-seal-brown-2;
$header-user-name-color: $color-peach-puff;

$header-user-toggle-background-color: $color-bistre;
$header-user-toggle-background-color-gradient: $color-deep-bronze;
$header-user-toggle-after-color: $color-raffia;

// header site title
$header-title-color: $color-brown-pod;
$header-title-h1-color: $color-brown-pod-2;

// header team
$header-team-background-color: $color-deep-bronze;
$header-team-rating-color: $color-pale-taupe;
$header-team-name-color: $color-negroni;
$header-team-resource-border-color: $color-roman-coffee;


// footer
$footer-container-background-color: $color-black;

$footer-site-map-title-color: $color-cream-can;
$footer-site-map-title-shadow-color: $color-black;
$footer-site-map-title-icon-color: $color-alpine;
$footer-site-map-title-icon-shadow-color: $color-black;
$footer-site-map-line-background-gradient-from-color: $color-fire-bush;
$footer-site-map-line-background-gradient-to-color: $color-black;

$footer-site-map-list-color: $color-alpine;
$footer-site-map-list-icon-color: $color-hot-curry;
$footer-site-map-list-link-color: $color-roti;
$footer-site-map-list-link-hover-color: $color-cream-can;

$footer-bottom-shadow-color: $color-black;
$footer-bottom-copyright-color: $color-teak;
$footer-bottom-links-link-color: $color-teak;





/**
 * Components
 */

// card
$c-card-border-color: $color-shingle-fawn;
$c-card-background-color: $color-black;
$c-card-border-outer-color: $color-black;
$c-card-pic-border-inset-color: $color-black;
$c-card-lvl-text-color: $color-shadow-2;
$c-card-lvl-nr-color: $color-white-smoke;
$c-card-text-shadow-color: $color-black;
$c-card-pos-name-color: $color-quincy;

$c-card-empty-background-color: $color-seal-brown-8;
$c-card-empty-border-color: $color-bracken;
$c-card-empty-border-inset-color: $color-black;

$c-card-fng-border-color: $color-ce-soir;

// rank
$c-rank-difficulty-color: $color-white;
$c-rank-0-color: $color-sorrell-brown;
$c-rank-1-color: $color-putty;
$c-rank-2-color: $color-apache;
$c-rank-3-color: $color-apache-2;
$c-rank-4-color: $color-whiskey-sour;
$c-rank-5-color: $color-whiskey-sour-2;
$c-rank-6-color: $color-japonica;
$c-rank-7-color: $color-japonica-2;
$c-rank-8-color: $color-valencia;
$c-rank-9-color: $color-mandy;
$c-rank-10-color: $color-cabaret;
$c-rank-11-color: $color-mulberry;
$c-rank-12-color: $color-fuchsia;
$c-rank-13-color: $color-medium-orchid;
$c-rank-14-color: $color-medium-orchid-2;
$c-rank-15-color: $color-medium-orchid-3;
$c-rank-16-color: $color-amethyst;
$c-rank-17-color: $color-slate-blue;
$c-rank-18-color: $color-slate-blue;

$c-rank-inverse-difficulty-color: $color-white;
$c-rank-0-inverse-color: $color-barley-corn;
$c-rank-1-inverse-color: $color-tallow;
$c-rank-2-inverse-color: $color-husk;
$c-rank-3-inverse-color: $color-husk-2;
$c-rank-4-inverse-color: $color-luxor-gold;
$c-rank-5-inverse-color: $color-tuscany;
$c-rank-6-inverse-color: $color-tuscany-2;
$c-rank-7-inverse-color: $color-tuscany-3;
$c-rank-8-inverse-color: $color-apple-blossom;
$c-rank-9-inverse-color: $color-roof-terracotta;
$c-rank-10-inverse-color: $color-night-shadz;
$c-rank-11-inverse-color: $color-royal-heath;
$c-rank-12-inverse-color: $color-royal-heath-2;
$c-rank-13-inverse-color: $color-deep-lilac;
$c-rank-14-inverse-color: $color-vivid-violet;
$c-rank-15-inverse-color: $color-royal-purple;
$c-rank-16-inverse-color: $color-royal-purple-2;
$c-rank-17-inverse-color: $color-daisy-bush;
$c-rank-18-inverse-color: $color-daisy-bush;

// ele
$c-ele-background-color: $color-dark-gray;
$c-ele-border-spotlight-color: $color-white-smoke;
$c-ele-border-shade-color: $color-nobel;
$c-ele-outer-border-color: $color-black;
$c-ele-icon-shadow-color: $color-suva-grey;

$c-ele-fire-background-color: $color-burnt-sienna;
$c-ele-fire-border-spotlight-color: $color-sweet-pink;
$c-ele-fire-border-shade-color: $color-mandy-2;
$c-ele-fire-icon-shadow-color: $color-milano-red-2;

$c-ele-wind-background-color: $color-medium-turquoise;
$c-ele-wind-border-spotlight-color: $color-french-pass;
$c-ele-wind-border-shade-color: $color-boston-blue;
$c-ele-wind-icon-shadow-color: $color-hippie-blue;

$c-ele-lightning-background-color: $color-laser;
$c-ele-lightning-border-spotlight-color: $color-mint-julep;
$c-ele-lightning-border-shade-color: $color-luxor-gold-2;
$c-ele-lightning-icon-shadow-color: $color-himalaya;

$c-ele-earth-background-color: $color-reef-gold;
$c-ele-earth-border-spotlight-color: $color-pavlova;
$c-ele-earth-border-shade-color: $color-harvest;
$c-ele-earth-icon-shadow-color: $color-raw-umber;

$c-ele-water-background-color: $color-picton-blue;
$c-ele-water-border-spotlight-color: $color-echo-blue;
$c-ele-water-border-shade-color: $color-steel-blue;
$c-ele-water-icon-shadow-color: $color-lochmara;

// exp
$c-exp-background-gradient-from-color: $color-seal-brown-3;
$c-exp-background-gradient-to-color: $color-deep-bronze-2;
$c-exp-pseudo-border-right-color: $color-spice;
$c-exp-pseudo-border-top-gradient-from-color: $color-seal-brown-3;
$c-exp-pseudo-border-top-gradient-to-color: $color-shadow;

$c-exp-fill-background-gradient-from-color: $color-seal-brown-3;
$c-exp-fill-background-gradient-to-color: $color-corn-harvest;
$c-exp-fill-pseudo-border-right-color: $color-clay-creek;
$c-exp-fill-pseudo-border-top-gradient-from-color: $color-seal-brown-3;
$c-exp-fill-pseudo-border-top-gradient-to-color: $color-soft-amber;

$c-exp-left-color: $color-raffia-2;

$c-exp-bloodline-background-gradient-from-color: $color-seal-brown-4;
$c-exp-bloodline-background-gradient-to-color: $color-moccaccino;
$c-exp-bloodline-pseudo-border-right-color: $color-very-dark-brown;
$c-exp-bloodline-pseudo-border-top-gradient-from-color: $color-seal-brown-4;
$c-exp-bloodline-pseudo-border-top-gradient-to-color: $color-solid-pink;

$c-exp-bloodline-fill-background-gradient-from-color: $color-seal-brown-4;
$c-exp-bloodline-fill-background-gradient-to-color: $color-solid-pink-2;
$c-exp-bloodline-fill-pseudo-border-right-color: $color-rose-taupe;
$c-exp-bloodline-fill-pseudo-border-top-gradient-from-color: $color-seal-brown-4;
$c-exp-bloodline-fill-pseudo-border-top-gradient-to-color: $color-careys-pink;

$c-exp-bloodline-left-color: $color-rose;

// bar
$c-bar-border-color: $color-spice-2;
$c-bar-background-gradient-from-color: $color-bakers-chocolate-3;
$c-bar-background-gradient-to-color: $color-tyrian-purple;
$c-bar-border-inset-color: $color-black;
$c-bar-fill-background-color: $color-mango-tango-2;
$c-bar-fill-border-inset-spotlight-color: $color-olive;
$c-bar-fill-border-inset-shade-color: $color-mango-tango-2;
$c-bar-text-shadow-color: $color-black;
$c-bar-text-cur-color: $color-white;
$c-bar-text-max-color: $color-cloudy;

$c-bar-we-fill-background-color: $color-la-rioja;
$c-bar-we-fill-border-inset-spotlight-color: $color-olive-2;
$c-bar-we-fill-border-inset-shade-color: $color-la-rioja;

$c-bar-ae-fill-background-color: $color-harlequin;
$c-bar-ae-fill-border-inset-spotlight-color: $color-islamic-green;
$c-bar-ae-fill-border-inset-shade-color: $color-harlequin;

$c-bar-genjutsu-fill-background-color: $color-pacific-blue;
$c-bar-genjutsu-fill-border-inset-spotlight-color: $color-cerulean;
$c-bar-genjutsu-fill-border-inset-shade-color: $color-pacific-blue;
$c-bar-genjutsu-text-max-color: $color-light-cyan;

$c-bar-achievement-fill-background-color: $color-scampi;
$c-bar-achievement-fill-border-inset-spotlight-color: $color-victoria;
$c-bar-achievement-fill-border-inset-shade-color: $color-scampi;
$c-bar-achievement-text-max-color: $color-tropical-blue;

$c-bar-bloodline-border-color: $color-van-cleef-2;
$c-bar-bloodline-fill-background-color: $color-turkish-rose;
$c-bar-bloodline-fill-border-inset-spotlight-color: $color-lotus;
$c-bar-bloodline-fill-border-inset-shade-color: $color-turkish-rose;
$c-bar-bloodline-text-max-color: $color-pink;

$c-bar-health-fill-background-color: $color-fire-engine-red-2;
$c-bar-health-fill-border-inset-spotlight-color: $color-venetian-red;
$c-bar-health-fill-border-inset-shade-color: $color-fire-engine-red-2;
$c-bar-health-text-max-color: $color-pink;

$c-bar-bukijutsu-border-color: $color-jacko-bean;
$c-bar-bukijutsu-background-gradient-from-color: $color-birch;
$c-bar-bukijutsu-background-gradient-to-color: $color-seal-brown-6;
$c-bar-bukijutsu-fill-background-color: $color-calico;
$c-bar-bukijutsu-fill-border-inset-spotlight-color: $color-sorrell-brown-3;
$c-bar-bukijutsu-fill-border-inset-shade-color: $color-calico;
$c-bar-bukijutsu-text-cur-color: $color-rum-swizzle;
$c-bar-bukijutsu-text-max-color: $color-cloudy;

$c-bar-bukijutsu-inactive-background-color: $color-seal-brown-7;
$c-bar-bukijutsu-inactive-fill-background-color: $color-beaver;
$c-bar-bukijutsu-inactive-fill-border-inset-spotlight-color: $color-tabacco-brown;
$c-bar-bukijutsu-inactive-fill-border-inset-shade-color: $color-beaver;

// filter buttom
$c-filter-button-background-color: $color-cola;
$c-filter-button-color: $color-almond-frost;
$c-filter-button-border-color: $color-seal-brown-5;
$c-filter-button-border-inset-spotlight-color: $color-saddle;
$c-filter-button-border-inset-shade-color: $color-cocoa-brown-3;
$c-filter-button-hover-background-color: $color-slugger;
$c-filter-button-hover-color: $color-silk;

$c-filter-button-active-background-color: $color-saddle-brown;
$c-filter-button-active-color: $color-white;
$c-filter-button-active-border-inset-spotlight-color: $color-au-chico;
$c-filter-button-active-border-inset-shade-color: $color-caput-mortuum;
$c-filter-button-active-hover-background-color: $color-saddle-brown;
$c-filter-button-active-hover-color: $color-white;

$c-filter-button-sel-background-color: $color-cape-palliser;
$c-filter-button-sel-color: $color-white;
$c-filter-button-sel-border-inset-spotlight-color: $color-sorrell-brown-2;
$c-filter-button-sel-border-inset-shade-color: $color-indian-tan;
$c-filter-button-sel-hover-background-color: $color-cape-palliser;
$c-filter-button-sel-hover-color: $color-white;

// option button
$c-option-button-background-color: $color-chiccolato;
$c-option-button-color: $color-derby;
$c-option-button-border-color: $color-seal-brown-5;
$c-option-button-border-inset-spotlight-color: $color-cement;
$c-option-button-border-inset-shade-color: $color-indian-tan-2;

$c-option-button-active-background-color: $color-saddle-brown;
$c-option-button-active-color: $color-white;
$c-option-button-active-border-inset-spotlight-color: $color-au-chico;
$c-option-button-active-border-inset-shade-color: $color-caput-mortuum;

// notification
$c-notification-background-color: $color-fire-engine-red;
$c-notification-border-top-color: $color-fuzzy-wuzzy-brown;
$c-notification-border-right-color: $color-falu-red-2;
$c-notification-border-bottom-color: $color-falu-red-3;
$c-notification-border-left-color: $color-mahogny;

$c-notification-outside-border-color: $color-black;

$c-notification-text-color: $color-white;
$c-notification-icon-color: $color-white;

// resource
$c-resource-color: $color-paris-daisy;
$c-resource-border-color: $color-spice-2;
$c-resource-background-linear-from-color: $color-bakers-chocolate-2;
$c-resource-background-linear-to-color: $color-tyrian-purple;
$c-resource-border-inset-color: $color-black;

$c-resource-gold-color: $color-golden-poppy;

$c-resource-we-color: $color-paris-daisy;

$c-resource-ae-color: $color-screamin-green;

$c-resource-diamonds-color: $color-mango-tango;

$c-resource-chakra-color: $color-suva-grey;
$c-resource-chakra-b-color: $color-very-light-grey;

$c-resource-stats-color: $color-tumbleweed;
$c-resource-stats-icon-color: $color-tumbleweed;

$c-resource-rating-color: $color-dark-salmon;
$c-resource-rating-rank-shadow-color: $color-black;

$c-resource-rating-loss-color: $color-red;
$c-resource-rating-loss-border-color: $color-tosca;
$c-resource-rating-loss-background-gradient-from-color: $color-falu-red;
$c-resource-rating-loss-background-gradient-to-color: $color-tyrian-purple-2;
$c-resource-rating-loss-rank-color: $color-mandy;

$c-resource-rating-win-color: $color-malachite;
$c-resource-rating-win-border-color: $color-hunter-green;
$c-resource-rating-win-background-gradient-from-color: $color-crusoe;
$c-resource-rating-win-background-gradient-to-color: $color-dark-green;
$c-resource-rating-win-rank-color: $color-emerald-2;

$c-resource-time-color: $color-suva-grey;
$c-resource-time-text-color: $color-silver;
$c-resource-time-b-color: $color-very-light-grey;

// pos
$c-pos-genin-color: $color-dark-sea-green;
$c-pos-jounin-color: $color-echo-blue-2;
$c-pos-kage-color: $color-charm;
$c-pos-summon-color: $color-lilac;

// button
$c-button-background-color: $color-forest-green;
$c-button-border-color: $color-seal-brown-5;
$c-button-border-inset-spotlight-color: $color-asparagus;
$c-button-border-inset-shade-color: $color-verdun-green;
$c-button-text-color: $color-white;
$c-button-text-shadow-color: $color-black;

$c-button-yes-background-color: $color-forest-green;
$c-button-yes-border-inset-spotlight-color: $color-asparagus;
$c-button-yes-border-inset-shade-color: $color-verdun-green;
$c-button-yes-hover-background-color: $color-forest-green-2;

$c-button-no-background-color: $color-milano-red;
$c-button-no-border-inset-spotlight-color: #BD6262;
$c-button-no-border-inset-shade-color: #661717;
$c-button-no-hover-background-color: #842525;

$c-button-cancel-background-color: $color-suva-grey;
$c-button-cancel-border-inset-spotlight-color: #C0C0C0;
$c-button-cancel-border-inset-shade-color: #616161;

$c-button-themed-yes-background-color: $color-cape-palliser;
$c-button-themed-yes-border-inset-spotlight-color: $color-sorrell-brown-2;
$c-button-themed-yes-border-inset-shade-color: $color-indian-tan;
$c-button-themed-yes-hover-background-color: $color-bracken-2;

$c-button-themed-disabled-background-color: $color-cola;
$c-button-themed-disabled-color: $color-almond-frost;
$c-button-themed-disabled-border-inset-spotlight-color: $color-kabul;
$c-button-themed-disabled-border-inset-shade-color: $color-cocoa-brown-4;





/**
 * Modules
 */

// lineup
$m-lineup-selectable-card-border-color: $color-parsley;
$m-lineup-editable-card-hover-border-color: $color-milano-red;
$m-lineup-highlight-valid-pos-card-border-color: $color-emerald;
$m-lineup-highlight-invalid-pos-card-border-color: $color-bracken;