.c-box-invert {
	position: relative;

	margin: 0 2px 1px 2px;
	padding: 4px 5px 3px 10px;

	border: 1px solid #523b29;

	background-color: #211507;


	&:before {
		content: '';

		position: absolute;
		top: 0;
		right: -3px;
		bottom: -3px;
		left: 0;

		border-style: solid;
		border-color: #000;
		border-width: 0 2px 2px 0;
	}

	&:after {
		content: '';

		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;

		border-style: solid;
		border-color: #000;
		border-width: 4px 0 0 4px;
	}


	&__inner {
		position: relative;

		z-index: 1;
	}


	&.-margin-top {
		margin-top: 20px;
	}
}


/**
 * Padding options.
 */
.c-box-invert-padding {
	position: relative;

	z-index: 1;


	&.-c-general {
		padding: 6px 6px 10px 6px;
	}

	&.-c-filters {
		padding: 8px 6px 2px 6px;
	}
}


/**
 * List section.
 */
.c-box-invert-list {
	$c: &;

	display: flex;
	flex-flow: column nowrap;


	&__row {
		position: relative;

		display: flex;
		align-items: center;

		padding: 8px;


		&.-c-hide {
			&:before {
				content: '';

				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;

				background-color: rgba(#211507, 0.8);

				z-index: 2;
			}
		}
	}

	&__equipped {
		flex: 0 0 30px;

		margin: 0 10px 0 10px;

		font-size: 20px;
		text-align: center;
		text-shadow: 1px 1px 1px #000;

		color: #584533;
	}

	&__cost {
		flex: 0 0 70px;

		display: flex;
		align-items: center;
		justify-content: flex-end;

		width: 70px;
		margin: 0 10px;

		text-shadow: 1px 1px 1px #000;

		color: #e9b500;


		span {
			position: relative;
			top: 1px;

			padding-right: 5px;

			font-size: 0.75rem;
		}

		i {
			font-size: 20px;
		}
	}


	&.-c-striped {
		#{$c}__row {
			&:nth-child(odd) {
				background-color: #2b1d0e;


				&.-c-hide {
					&:before {
						background-color: rgba(#2B1D0E, 0.8);
					}
				}
			}
		}
	}
}