/**
 * Breakpoints
 */

// phone only
@mixin for-phone-only {
	@media screen and (max-width: 599px) {
		@content;
	}
}

// phone landscape and bigger
@mixin for-phone-landscape-up {
	@media screen and (min-width: 480px) {
		@content;
	}
}

// tablet and bigger
@mixin for-tablet-up {
	@media screen and (min-width: 768px) {
		@content;
	}
}

// desktop and bigger
@mixin for-desktop-up {
	@media screen and (min-width: 960px) {
		@content;
	}
}

// larger desktop and bigger
@mixin for-desktop-big-up {
	@media screen and (min-width: 1038px) {
		@content;
	}
}





/**
 * Animations
 */

// attack animation
@mixin attack-animation($name: 'atk-a', $side: '0') {
	$name: #{$name}-#{$side};
	$sidePosition: if($side == '0', 700, -700);

	@keyframes #{$name} {
		0% {
			opacity: 1;
		}

		35% {
			transform: scale(0.3);
		}

		90% {
			transform: translateX(unquote($sidePosition + 'px')) scale(0.3);
			opacity: 0;
		}

		91% {
			transform: translateX(0px) scale(1);
		}

		100% {
			opacity: 1;
		}
	}
}





/**
 * Misc
 */

// color flashing animation
@mixin color-flashing($name, $color: '#fff') {
	@keyframes #{$name} {
		75%,
		100% {
			color: $color;
		}
	}
}





/**
 * Theme
 */

// specific theme content
@mixin theme($theme-name: 'akatsuki-dawn') {
	@if $theme == $theme-name {
		@content;
	}
}





/**
 * Placeholder mixins that need to be in the correct order.
 */

// Placeholder for shared active details of:
//      components/_overlay.scss
@mixin c-overlay-active {
	display: flex;

	background-color: rgba(#291d12, 0.8);

	z-index: 30;
	opacity: 1;
}