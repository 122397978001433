.c-overlay {
	$c: &;

	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;

	display: none;
	align-items: center;
	justify-content: center;

	width: 100%;
	height: 100%;

	background-color: rgba(#291d12, 0);

	z-index: -30;
	overflow: hidden;
	opacity: 0;


	&__loader {
		&:before {
			@extend %font-nm;

			content: "\e83c";

			line-height: 120px;
		}

		font-size: 0px;
		line-height: 1.4;
		text-shadow: 1px 1px 2px $shadow-color-strongest;

		color: #7e6d56;


		&.-c-window-centered {
			position: absolute;
			right: 0;
			left: 0;

			width: 80px;
			margin: 0 auto;
		}
	}


	&.-loader-spin {
		#{$c}__loader {
			font-size: 80px;
		}
	}

	&.-loader-grow {
		#{$c}__loader {
			font-size: 1px;

			transition: font 2s ease;
		}
	}

	&.-c-active {
		@include c-overlay-active;


		&#{$c}.-loader-grow {
			#{$c}__loader {
				font-size: 80px;
			}
		}

		&#{$c}.-loader-spin {
			#{$c}__loader {
				animation: spin 2s infinite linear;
			}
		}
	}

	&.-c-invisible {
		background-color: rgba(0,0,0, 0);

		#{$c}__loader {
			display: none;
		}
	}
}