.c-page-table {
	border-collapse: collapse;

	margin: 15px 40px;

	font-size: 0.775em;
	line-height: 1.4;


	thead {
		tr {
			border-bottom: 1px solid #e1c292;

			color: #e1c292;
		}
	}

	tr {
		color: #c1ab88;
	}

	td {
		padding-top: 6px;
		padding-right: 20px;


		&:first-child {
			width: 30px;

			white-space: nowrap;
		}

		&:last-child {
			padding-right: 0;
		}
	}
}