// importing base
@import '../base/colors',
		'../base/variables';

// importing helpers
@import '../utilities/mixins',
		'../utilities/placeholders';


hr {
	border-color: #655343;
}
