.c-resource {
	$c: &;

	position: relative;

	display: flex;
	align-items: center;
	justify-content: flex-end;

	height: 24px;

	border: 1px solid $c-resource-border-color;
	border-radius: 4px;

	line-height: 1.2;

	background: linear-gradient($c-resource-background-linear-from-color, $c-resource-background-linear-to-color);
	color: $c-resource-color;


	&:before {
		content: "";

		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;

		border: 2px solid $c-resource-border-inset-color;
		border-radius: 3px;
	}


	> span {
		position: relative;

		padding: 0 3px 0 6px;

		font-size: 0.8rem;
		text-align: right;
		text-shadow: -1px -1px 0 #000;
	}


	&__icon {
		position: relative;
		top: 0;
		right: 1px;

		width: 26px;

		font-size: 20px;
		text-align: center;
		text-shadow: 0 0 1px #000;


		&:before {
			@extend %font-nm;
		}
	}

	i {
		position: relative;
		top: 0;
		right: 1px;

		width: 26px;

		font-size: 14px;
		text-align: center;
		text-shadow: 0 0 1px #000;
	}


	&.-resource-gold {
		color: $c-resource-gold-color;


		#{$c}__icon {
			top: -2px;

			font-size: 20px;


			&:before {
				content: '\e824';
			}
		}
	}

	&.-resource-gems {
		color: #7ad5ea;


		#{$c}__icon {
			font-size: 18px;


			&:before {
				content: '\e905';
			}
		}
	}

	&.-resource-kage {
		color: #d89856;


		#{$c}__icon {
			font-size: 22px;


			&:before {
				content: '\e897';
			}
		}
	}

	&.-resource-we,
	&.-resource-ae,
	&.-resource-ce {
		#{$c}__icon {
			width: 20px;

			font-size: 13px;


			&:before {
				content: '\e82e';
			}
		}
	}

	&.-resource-we {
		color: $c-resource-we-color;
	}

	&.-resource-ae {
		color: $c-resource-ae-color;
	}

	&.-resource-ce {
		color: #4cbdff;
	}

	&.-resource-diamonds {
		color: $c-resource-diamonds-color;


		#{$c}__icon {
			top: 1px;
			right: 0;

			font-size: 17px;


			&:before {
				content: '\e826';
			}
		}
	}

	&.-resource-chakra {
		color: $c-resource-chakra-color;


		b {
			color: $c-resource-chakra-b-color;

			font-size: 0.8rem;
		}


		#{$c}__icon {
			top: 0;

			font-size: 15px;


			&:before {
				content: '\e803';
			}
		}
	}

	&.-c-chakra-lineup {
		> span {
			font-size: 0.7rem;
		}

		b {
			padding-right: 1px;
		}
	}

	&.-resource-stats {
		color: $c-resource-stats-color;


		#{$c}__icon {
			top: -1px;

			font-size: 18px;

			color: $c-resource-stats-icon-color;


			&:before {
				content: '\e821';
			}
		}
	}

	&.-resource-rating {
		color: $c-resource-rating-color;


		// component
		.c-rank {
			position: relative;
			top: -1px;

			width: 26px;

			text-shadow: 0 0 1px $c-resource-rating-rank-shadow-color;
		}
	}

	&.-rating-loss {
		color: $c-resource-rating-loss-color;
		border-color: $c-resource-rating-loss-border-color;
		background: linear-gradient($c-resource-rating-loss-background-gradient-from-color, $c-resource-rating-loss-background-gradient-to-color);


		// component
		.c-rank {
			&:before {
				color: $c-resource-rating-loss-rank-color;
			}
		}
	}

	&.-rating-win {
		color: $c-resource-rating-win-color;
		border-color: $c-resource-rating-win-border-color;
		background: linear-gradient($c-resource-rating-win-background-gradient-from-color, $c-resource-rating-win-background-gradient-to-color);


		// component
		.c-rank {
			&:before {
				color: $c-resource-rating-win-rank-color;
			}
		}
	}

	&.-resource-time {
		width: auto;

		color: $c-resource-time-color;


		> span {
			color: $c-resource-time-text-color;

			font-size: 0.6rem;
			text-transform: uppercase;
		}


		b {
			margin-right: 2px;

			color: $c-resource-time-b-color;

			font-size: 0.8rem;
		}


		#{$c}__icon {
			width: 22px;

			font-size: 13px;


			&:before {
				content: '\e86f';
			}
		}
	}

	&.-opacity-high {
		opacity: 0.25;
	}

	&.-opacity-low {
		opacity: 0.25;
	}
}
