.c-difficulty-estimate {
	position: relative;

	display: inline-flex;
	align-items: center;

	padding: 0 1px;

	text-shadow: 1px 1px 1px #000;

	color: #AE8B65;


	span {
		font-size: 0.6rem;
		text-transform: uppercase;
	}


	&__icon {
		@extend %font-nm;

		flex: 0 0 15px;

		width: 15px;
		margin: 0 2px;

		font-size: 11px;


		&:before {
			content: '\e81c';
		}
	}


	// unknown
	&.-diff-0 {

	}

	// very easy
	&.-diff-1 {
		color: #AE8B65;
	}

	// easy
	&.-diff-2 {
		color: #D08650;
	}

	// medium
	&.-diff-3 {
		color: #DA6A1A;
	}

	// challenging
	&.-diff-4 {
		color: #DD3D20;
	}

	// very hard
	&.-diff-5 {
		color: #F0116E;
	}

	// impossible
	&.-diff-6 {
		color: #E211C8;
	}

	// forbidden
	&.-diff-7 {
		color: #C800FF;
	}
}