.c-box {
	$c: &;

	@extend %c-box;


	&__bg {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;


		&:before {
			content: '';

			position: absolute;
			top: 0;
			right: 0;
			left: 0;

			height: 100%;
		}

		&:after {
			content: '';

			position: absolute;
			top: 0;
			right: 0;
			left: 0;

			height: 100%;

			background: linear-gradient(to left, rgba(#543C2A, 0.75), #543C2A 55%);
		}


		&.-bg-dna {
			&:before {
				background: url('#{$gfx-path}/themes/#{$theme}/shared/row_dna_bg.jpg') top right repeat;
			}
		}

		&.-bg-inline {
			position: absolute;
			top: 0;
			right: 0;
			left: 0;

			height: 100%;

			background-position: 100% 50%;
			background-repeat: no-repeat;
			background-color: #000;

			overflow: hidden;


			&:before {
				content: none;
			}

			&:after {
				left: 0;
				background: linear-gradient(to left, rgba(#543C2A, 0.35), #543C2A 65%);
			}
		}

		&.-bg-middle {
			&:after {
				background: linear-gradient(to left, #543C2A, rgba(#543C2A, 0.3), #543C2A 65%);
			}
		}
	}

	&__details {
		flex: 1 0 auto;

		position: relative;

		display: flex;

		min-width: 0;
		padding: 8px;
	}

	&__extra {
		flex: 1 0 auto;

		position: relative;

		display: flex;

		width: 100%;

		background-color: #4c3421;
	}

	&__notification {
		top: -3px;
		left: 5px;

		z-index: 3;


		&.-c-center {
			left: 50%;
		}


		// component
		&.c-notification {
			position: absolute;
		}
	}


	&.-c-info {
		margin: 2px;
		padding: 10px 20px;

		border: 1px solid #A98D7A;

		text-align: center;

		background-color: #6D5746;


		&:before {
			top: -3px;
			left: -3px;
			right: -3px;
			bottom: -3px;

			border-width: 1px;
			border-radius: 3px;
		}


		p {
			margin: 0;
			padding: 0;

			font-size: 0.75rem;
			line-height: 1.2;
			text-shadow: 1px 1px 1px #000;

			color: #D0BC9F;
		}


		&.-c-header {
			border-color: #4c4034;
			background-color: #382a1e;


			p {
				color: #8c7e69;
			}
		}

		&.-margin-1 {
			margin: 1px;
		}
	}
}




/**
 * Info section.
 */
.c-box-info {
	position: relative;

	flex: 1 1 auto;

	display: flex;
	flex-flow: row wrap;

	min-width: 0;
	margin: 0 5px;

	text-shadow: 1px 1px 1px #000;


	&__top {
		position: relative;

		flex: 1 1 auto;

		width: 100%;
		min-width: 0;

		line-height: 1.2;


		// component
		.c-underline {
			margin-top: -1px;
		}
	}

	&__name {
		position: relative;

		flex: 0 1 auto;

		min-width: 0;

		font-size: 1.1rem;
		text-overflow: ellipsis;
		white-space: nowrap;

		color: #FFB56B;

		overflow: hidden;

		z-index: 1;
	}

	&__desc {
		margin-top: 3px;

		font-size: 0.7rem;
		line-height: 1.0;

		color: #D6B186;
	}

	&__bot {
		flex: 0 1 auto;

		display: flex;
		flex-flow: column nowrap;
		justify-content: flex-end;

		width: 80%;
		margin-top: 10px;
	}
}
