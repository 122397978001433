.c-option-button {
	position: relative;

	display: flex;
	justify-items: center;
	align-items: center;

	width: 100%;
	height: 25px;
	margin-bottom: -2px;
	padding: 0 5px 0 10px;

	background: $c-option-button-background-color;
	color: $c-option-button-color;
	border: 2px solid $c-option-button-border-color;
	border-radius: 3px;

	cursor: pointer;


	&:before {
		content: "";

		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;

		border: 2px solid;
		border-color: $c-option-button-border-inset-spotlight-color
					 $c-option-button-border-inset-shade-color
					 $c-option-button-border-inset-shade-color
					 $c-option-button-border-inset-spotlight-color;
	}


	span {
		position: relative;
		top: -1px;

		max-width: 100%;
		padding: 0 1px;

		font-size: 0.65rem;
		text-align: left;
		text-shadow: 1px 1px 1px $shadow-color-strongest;
		text-overflow: ellipsis;

		overflow: hidden;
		white-space: nowrap;
	}


	&--active {
		background: $c-option-button-active-background-color;
		color: $c-option-button-active-color;

		&:before {
			border-color: $c-option-button-active-border-inset-spotlight-color
						 $c-option-button-active-border-inset-shade-color
						 $c-option-button-active-border-inset-shade-color
						 $c-option-button-active-border-inset-spotlight-color;
		}
	}
}