.c-page-img {
	position: relative;

	display: inline-block;

	margin: 20px;


	&.-c-resize {
		padding: 1px;

		border: 2px solid #655443;

		&:before {
			content: '';

			position: absolute;
			top: -4px;
			right: -4px;
			bottom: -4px;
			left: -4px;

			border: 2px solid #19110b;
		}


		img {
			width: 100%;
		}
	}
}