.c-box-header {
	position: relative;

	height: 30px;
	margin: 0 2px 1px 2px;

	border: 1px solid #523b29;

	text-shadow: 1px 1px 0 #000;

	background-color: #1b0f05;


	&:before {
		content: '';

		position: absolute;
		top: 0;
		right: -3px;
		bottom: 0;

		border-right: 2px solid #000;
	}

	&:after {
		content: '';

		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;

		border-style: solid;
		border-color: #000;
		border-width: 4px 0 0 4px;
	}


	span {
		position: absolute;
		bottom: 3px;

		width: 100px;

		font-size: 0.6rem;
		font-weight: 700;
		text-transform: uppercase;
		text-align: center;

		white-space: nowrap;
		text-overflow: ellipsis;

		color: #7c5b3e;

		overflow: hidden;


		&.-align-left {
			text-align: left;
		}

		&.-align-right {
			text-align: right;
		}


		&.-pos-left {
			left: 10px;
		}

		&.-pos-right {
			right: 10px;
		}


		&.-mission-opponent {
			left: 9px;
		}

		&.-mission-mission {
			left: 70px;
		}

		&.-mission-difficulty {
			left: 190px;
		}

		&.-mission-rewards {
			right: 185px;
		}

		&.-mission-requirements {
			right: 20px;
		}


		&.-arena-rating {
			width: 90px;
		}

		&.-arena-name {
			left: 134px;
		}

		&.-arena-village {
			right: 170px;
		}

		&.-arena-inspect {
			right: 120px;
		}

		&.-arena-pow {
			right: 62px;
		}


		&.-challenges-summary {
			right: 195px;
		}

		&.-challenges-inspect {
			right: 110px;
		}

		&.-challenges-date {
			right: 30px;
		}

		&.-challenges-times {
			right: 7px;
		}

		&.-challenges-replay {
			right: 7px;
		}


		&.-attributes-name {
			left: 15px;
		}

		&.-attributes-total {
			left: 109px;
		}

		&.-attributes-ninja {
			left: 156px;
		}

		&.-attributes-equipment {
			left: 207px;
		}

		&.-attributes-seal {
			left: 253px;
		}

		&.-attributes-bloodline {
			left: 302px;
		}

		&.-attributes-stats {
			left: 351px;
		}

		&.-attributes-combos {
			left: 396px;
		}

		&.-attributes-summon {
			left: 446px;
		}

		&.-attributes-clan {
			left: 490px;
		}

		&.-attributes-village {
			left: 534px;
		}

		&.-attributes-effective {
			right: 15px;

			text-transform: none;
		}


		&.-ninja-card {
			left: 5px;
		}

		&.-ninja-abilities {
			left: 68px;
		}

		&.-ninja-cost {
			right: 203px;
		}

		&.-ninja-stats {
			right: 60px;
		}


		&.-inventory-item {
			left: 5px;
		}

		&.-inventory-icon {
			right: 208px;
		}

		&.-inventory-value {
			right: 130px;
		}


		&.-shop-service {
			left: 40px;
		}

		&.-shop-ninjas {
			right: 300px;
		}

		&.-shop-cost {
			right: 125px;
		}


		&.-genjutsu-button {
			right: 25px;
		}


		&.-leaderboards-position {
			left: 10px;
		}

		&.-leaderboards-team {
			left: 80px;
		}

		&.-leaderboards-ninja {
			right: 180px;
		}

		&.-leaderboards-power {
			right: 10px;
		}


		&.-sockets-extracted {
			right: 208px;
		}

		&.-sockets-capacity {
			right: 125px;
		}

		&.-sockets-button {
			right: -3px;
		}
	}


	&.-top-hidden {
		margin-top: -1px;

		border-top: 0;
	}
}
