.c-notification {
	position: relative;

	display: flex;
	align-items: center;

	padding: 3px 6px;

	line-height: 1.2;

	border: 1px solid;
	border-color: $c-notification-border-top-color
				$c-notification-border-right-color
				$c-notification-border-bottom-color
				$c-notification-border-left-color;
	border-radius: 0 0 2px 2px;

	background: $c-notification-background-color;


	&:before {
		content: '';

		position: absolute;
		top: 0;
		right: -3px;
		bottom: -3px;
		left: 0;

		border-style: solid;
		border-color: rgba($c-notification-outside-border-color, 0.4);
		border-width: 0 2px 2px 0;
		border-radius: 1px;
	}


	span {
		position: relative;

		font-size: 0.625rem;
		text-transform: uppercase;
		text-shadow: 1px 1px 1px $shadow-color-strongest;

		color: $c-notification-text-color;


		a {
			text-decoration: underline;

			color: inherit;
		}

		i {
			padding-right: 3px;

			font-size: 10px;
		}

		strong {
			color: #fff;
		}
	}

	&__icon {
		padding-right: 3px;

		font-size: 11px;
		text-shadow: 1px 1px 1px $shadow-color-strongest;

		color: $c-notification-icon-color;
	}


	&.-text-nowrap {
		span {
			display: inline-block;

			white-space: nowrap;
		}
	}

	&.-color-light {
		span {
			color: #f9d0d0;
		}
	}

	&.-size-thin {
		padding: 1px 4px;
	}
}
