.c-page-list {
	font-size: 0.775rem;
	line-height: 1.2;
	text-shadow: 1px 1px 1px #000;

	color: #c1ab88;


	li {
		padding: 2px 0;


		> b {
			color: #FFA027;
		}

		> em {
			color: #F79B64;
		}

		a {
			text-decoration: underline;

			color: #F1B26F;
		}

		p {
			margin: 5px 0 15px 0;
			padding: 0;

			line-height: 1.2;

			color: #c1ab88;
		}


		&.-c-title {
			list-style-type: none;

			margin: 3px 0 3px -20px;

			color: #e1c292;
		}
	}

	ol,
	ul {
		margin: 10px 0;

		color: #c1ab88;


		li {
			ol,
			ul {
				margin: 10px 0;
			}
		}
	}


	&.-margin-top-zero {
		margin-top: 0;
	}

	&.-style-none {
		margin-left: -15px;


		li {
			list-style-type: none;
		}
	}

	&.-list-stats {
		li {
			color: #e0a76a;


			> b {
				color: #f69024;
			}
		}
	}
}