.c-divider {
	position: relative;

	width: 675px;
	height: 50px;
	margin: 0 0 0 40px;


	&:after {
		content: '';

		position: absolute;
		top: -45px;
		left: 0;

		width: 675px;
		height: 92px;
		margin: 0 auto;

		background: url('#{$gfx-path}/themes/#{$theme}/shared/divider_area_1.png') center 0 no-repeat;
	}
}