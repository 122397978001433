.c-overlay-message {
	$c: &;

	position: absolute;
	top: 250px;
	left: 0;
	right: 0;

	display: none;
	width: 100%;
	min-height: 100px;
	margin: 0 auto;

	border-top: 3px solid #382819;
	border-bottom: 3px solid #382819;

	background-color: #211206;

	z-index: 31;


	&__inner {
		display: flex;
		flex-flow: column nowrap;
		align-items: center;

		margin: 40px 100px 40px 100px;
	}

	&__title {
		margin-bottom: 10px;

		font-size: 1.1em;
		text-align: center;

		color: #ccc;
	}

	&__text {
		margin-bottom: 10px;

		font-size: 0.9em;
		text-align: center;

		color: #ccc;


		b {
			color: #E2B753;

			em {
				margin-right: 4px;

				font-size: 0.7rem;
			}
		}

		a {
			text-decoration: underline;

			color: #ecc2a3;
		}
	}

	&__selection {
		display: table;

		margin: 10px 0 30px;
	}

	&__elements {
		display: flex;
		justify-content: center;


		// component
		.c-ele {
			margin-right: 8px;

			cursor: pointer;


			&:not(.-c-sel) {
				opacity: 0.25;
			}
		}
	}

	&__item {
		margin-bottom: 10px;


		// component
		.c-item {
			display: inline-flex;
		}

		// component
		.c-card {
			display: inline-flex;
		}

		// module
		.m-tooltip {
			display: inline-flex;

			margin: 15px auto 30px auto;

			text-align: initial;
		}
	}

	&__button {
		margin: 10px;
	}

	&__buttons {
		display: flex;
		margin: 10px;


		// component
		.c-button {
			margin: 0 10px;
		}
	}

	&__close {
		margin-top: 50px;
	}
}