.c-page-desc {
	$c: &;

	padding: 10px 2px;

	text-shadow: 1px 1px 2px #000;


	h1,
	h2,
	h3,
	h4,
	h5 {
		margin: 0;

		font-weight: 600;

		color: #f2e0c0;
	}

	h1 {
		font-size: 1.4rem;
	}

	h2 {
		font-size: 1.3rem;
	}

	h3 {
		font-size: 1rem;
	}

	h4 {
		font-size: 0.8rem;
	}

	h5 {
		font-size: 0.7rem;
		text-transform: uppercase;

		color: #b39c78;
	}

	p {
		margin: 0;

		font-size: 0.775rem;
		line-height: 1.1;

		color: #e1c292;


		b {
			color: #FFA027;
		}

		em {
			color: #F79B64;
		}

		a {
			text-decoration: underline;

			color: #F1B26F;
		}


		&.-c-warning {
			color: #FF5027;
		}

		&.-c-positive {
			color: #7fcc66;
		}

		&.-c-margin {
			margin: 0.5em 0;
		}
	}


	// full path class to override p tag above
	#{$c}__info {
		display: table;

		margin: 2px 5px 0 5px;

		font-size: 0.65rem;

		color: #CCA466;


		i {
			font-size: 9px;
		}

		> i:first-child {
			font-size: 18px;

			vertical-align: middle;
		}

		> b {
			color: #F79B64;
		}

		> a {
			text-decoration: underline;

			color: #e2aa6e;
		}
	}


	&--header {
		margin-top: 0;
		padding-top: 0;
	}


	&.-text-center {
		text-align: center;


		#{$c}__info {
			display: block;
		}
	}

	&.-margin-top {
		margin-top: 20px;
	}

	&.-margin-top-zero {
		margin-top: 0;
		padding-top: 0;
	}

	&.-margin-zero {
		margin: 0;
		padding: 0;
	}

	&.-type-area {
		h2 {
			font-size: 1.2rem;
		}

		h3 {
			font-size: 0.9rem;
		}

		p {
			font-size: 0.675rem;
		}
	}

	&.-color-faded {
		h1,
		h2,
		h3 {
			color: #c1af8e;
		}

		p {
			color: #ab936e
		}


		#{$c}__info {
			color: #846b55;
		}
	}

	&.-type-info {
		margin: 0.5em 0;

		color: #c1ab88;


		p {
			margin: 0.75em 0;

			color: #c1ab88;
		}
	}

	&.-type-note {
		min-height: 18px;


		#{$c}__info {
			line-height: 1.1;


			> i:first-child {
				float: left;

				padding: 0 10px 2px 0;
			}


			&.-c-single {
				> i:first-child {
					float: none;

					padding: 0 5px 0 0;
				}
			}
		}
	}
}