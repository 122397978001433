.c-fieldset {
	position: relative;

	padding: 16px 15px 15px 15px;

	border: 1px solid #473525;
	border-top: 0;


	&:before {
		content: '';

		position: absolute;
		top: -1px;
		right: -2px;
		bottom: -2px;
		left: -2px;

		border: 1px solid #150e09;
		border-top: 0;
	}


	&__title {
		position: absolute;
		top: -6px;
		right: 0;
		left: 0;

		display: flex;

		font-size: 0.65rem;
		line-height: 1.2;
		letter-spacing: 1px;
		text-transform: uppercase;
		text-shadow: 1px 1px 1px #000;

		color: #907961;

		white-space: nowrap;

		overflow: hidden;


		&:before,
		&:after {
			content: '';

			position: relative;
			bottom: 6px;

			width: 50%;

			border-bottom: 1px solid #150e09;

			box-shadow: 0 1px 0 #473525;
		}

		&:before {
			right: 8px;
		}

		&:after {
			left: 8px;
		}


		&.-c-left {
			&:before {
				width: 15%;
			}

			&:after {
				width: 85%;
			}
		}

		&.-c-right {
			&:before {
				width: 85%;
			}

			&:after {
				width: 15%;
			}
		}
	}

	&__inner {
		position: relative;
	}
}
