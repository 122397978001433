.c-pos {
	position: relative;


	&:before {
		@extend %font-nm;

		content: '\e806';

		font-size: 7px;
		line-height: 1.1;
		text-shadow: -1px -1px 0 $shadow-color-strongest,
					1px 1px 0 $shadow-color-strongest;
	}


	&.-pos-1 {
		color: $c-pos-genin-color;

		&:before {
			color: $c-pos-genin-color;
		}
	}

	&.-pos-2 {
		color: $c-pos-jounin-color;

		&:before {
			color: $c-pos-jounin-color;
		}
	}

	&.-pos-3 {
		color: $c-pos-kage-color;

		&:before {
			color: $c-pos-kage-color;
		}
	}

	&.-pos-4 {
		color: $c-pos-summon-color;

		&:before {
			color: $c-pos-summon-color;
		}
	}
}