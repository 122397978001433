.c-button {
	$c: &;

	position: relative;

	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;

	width: 90px;
	height: 35px;

	background-color: $c-button-background-color;

	border: 2px solid $c-button-border-color;
	border-radius: 4px;

	text-align: left;

	cursor: pointer;


	&:before {
		content: '';

		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;

		border: 3px solid;
		border-color: $c-button-border-inset-spotlight-color
					 $c-button-border-inset-shade-color
					 $c-button-border-inset-shade-color
					 $c-button-border-inset-spotlight-color;
		border-radius: 3px;
	}


	> span {
		position: relative;

		max-width: 100%;
		padding: 0 8px;

		font-weight: 600;
		font-style: italic;
		font-size: 0.8rem;
		line-height: 1.2;
		text-transform: uppercase;
		text-shadow: 1px 1px 1px $c-button-text-shadow-color;
		text-overflow: ellipsis;
		white-space: nowrap;

		color: #fff;

		overflow: hidden;


		> i {
			font-size: 0.9em;
		}
	}

	> i {
		flex: 0 0 25px;

		width: 25px;
		margin-left: 5px;

		font-size: 16px;
		text-align: center;
		text-shadow: 1px 1px 1px $c-button-text-shadow-color;

		color: #fff;
	}


	&__icon {
		flex: 0 0 25px;

		display: none;
		width: 25px;
		margin-left: 5px;

		font-size: 16px;
		text-align: center;
		text-shadow: 1px 1px 1px $c-button-text-shadow-color;

		color: #fff;


		&:before {
			@extend %font-nm;

			content: '';

			display: inline-block;
		}
	}

	&__nr {
		flex: 0 0 25px;

		width: 25px;

		font-size: 1rem;
		line-height: 1.2;
		text-shadow: 1px 1px 1px $c-button-text-shadow-color;

		color: #fff;


		&.-width-auto {
			width: auto;
		}
	}


	&.-color-yes {
		background-color: $c-button-yes-background-color;


		&:before {
			border-color: $c-button-yes-border-inset-spotlight-color
						 $c-button-yes-border-inset-shade-color
						 $c-button-yes-border-inset-shade-color
						 $c-button-yes-border-inset-spotlight-color;
		}

		&:hover {
			background-color: $c-button-yes-hover-background-color;
		}
	}

	&.-color-no {
		background-color: $c-button-no-background-color;


		&:before {
			border-color: $c-button-no-border-inset-spotlight-color
						 $c-button-no-border-inset-shade-color
						 $c-button-no-border-inset-shade-color
						 $c-button-no-border-inset-spotlight-color;
		}

		&:hover {
			background-color: $c-button-no-hover-background-color;
		}
	}

	&.-color-cancel {
		background-color: $c-button-cancel-background-color;


		&:before {
			border-color: $c-button-cancel-border-inset-spotlight-color
						 $c-button-cancel-border-inset-shade-color
						 $c-button-cancel-border-inset-shade-color
						 $c-button-cancel-border-inset-spotlight-color;
		}
	}

	&.-color-themed-yes {
		background-color: $c-button-themed-yes-background-color;


		&:before {
			border-color: $c-button-themed-yes-border-inset-spotlight-color
						 $c-button-themed-yes-border-inset-shade-color
						 $c-button-themed-yes-border-inset-shade-color
						 $c-button-themed-yes-border-inset-spotlight-color;
		}

		&:hover {
			background-color: $c-button-themed-yes-hover-background-color;
		}
	}

	&.-color-disabled {
		background-color: $c-button-themed-disabled-background-color;


		&:before {
			border-color: $c-button-themed-disabled-border-inset-spotlight-color
						 $c-button-themed-disabled-border-inset-shade-color
						 $c-button-themed-disabled-border-inset-shade-color
						 $c-button-themed-disabled-border-inset-spotlight-color;
		}


		> span {
			color: $c-button-themed-disabled-color;
		}
	}

	&.-color-section {
		background-color: #834824;


		&:before {
			border-color: #B07B5E
						#5E351E
						#5E351E
						#B07B5E;
		}

		&:hover {
			background-color: #6a391b;
		}

		> i {
			&.a-button-color {
				text-shadow: 1px 1px 1px #ba9075,
							-1px -1px 0 #000;

				color: #5f3001;
			}
		}


		&.-c-sel {
			background-color: #A6642C;


			> i {
				text-shadow: 1px 1px 1px #E4C0AA,
							-1px -1px 0 #000;

				color: #4A2001;
			}
		}
	}


	&.-type-section {
		display: inline-flex;
		justify-content: flex-start;

		width: auto;
		min-width: 140px;
		height: 53px;


		> span {
			padding: 1px 16px 2px 6px;

			font-size: 0.95rem;
			line-height: 1.1;

			color: #ffd9c1;


			b {
				display: block;
				margin: -2px 0;

				font-size: 0.85rem;
				font-weight: 600;

				color: #e8b597;
			}
		}

		> i {
			position: relative;
			top: 1px;

			flex: 0 0 40px;

			width: 40px;
			margin-left: 7px;

			font-size: 26px;
		}


		&.-size-sub {
			height: 44px;


			> span {
				padding: 1px 16px 2px 6px;

				font-size: 0.8rem;
				text-transform: none;


				b {
					font-size: 0.65rem;
				}
			}

			> i {
				flex-basis: 30px;

				width: 30px;
				margin-left: 7px;

				font-size: 18px;
			}
		}
	}

	&.-size-btn {
		justify-content: center;

		width: 40px;
		min-width: 40px;
		height: 40px;


		&:before {
			border-width: 2px;
		}


		> span {
			display: none;
		}

		> i {
			top: auto;

			flex: 0 0 40px;
			margin: 0;

			font-size: 20px;
		}
	}


	&.-size-s {
		width: 70px;
		height: 27px;

		border-radius: 4px;


		&:before {
			border-radius: 2px;
		}


		> span {
			top: -1px;

			padding: 0 3px 0 3px;

			font-size: 0.65rem;
		}

		> i {
			font-size: 14px;

			flex-basis: 18px;
			width: 18px;
		}


		&.-width-auto {
			span {
				padding-left: 10px;
				padding-right: 10px;
			}


			&.-c-icon {
				span {
					padding-left: 3px;
				}
			}
		}
	}

	&.-size-m {
		height: 41px;

		border-radius: 5px;


		&:before {
			border-radius: 3px;
		}


		> span {
			padding: 0 10px 0 10px;

			font-size: 1rem;
		}

		> i {
			font-size: 18px;

			margin-left: 5px;
		}


		&.-width-auto {
			span {
				padding-left: 24px;
				padding-right: 24px;
			}

			> i,
			#{$c}__icon {
				margin-left: 8px;
			}


			&.-c-icon {
				span {
					padding-left: 10px;
				}
			}
		}
	}

	&.-size-l {
		min-width: 230px;
		height: 56px;

		border-radius: 7px;


		&:before {
			border-radius: 5px;
		}


		> span {
			font-size: 1.2rem;
			line-height: 1.4;

			padding: 0 14px 0 14px;
		}

		> i {
			font-size: 24px;

			margin-left: 10px;
		}
	}

	&.-width-auto {
		display: inline-flex;
		width: auto;


		> span {
			padding-left: 16px;
			padding-right: 16px;
		}

		> i,
		&__icon {
			margin-left: 5px;
		}


		&.-c-icon {
			> span {
				padding-left: 8px;
			}
		}
	}

	&.-text-center {
		justify-content: center;


		> span {
			flex: 1 0 auto;

			text-align: center;
		}
	}

	&.-text-right {
		> span {
			margin-left: auto;
		}
	}

	&.-energy-arena {
		> span {
			color: #d1fbb6;
		}
	}

	&.-c-icon {
		justify-content: flex-start;


		#{$c}__icon {
			display: block;
			margin-right: -5px;
		}
	}

	&.-c-icon-text {

		> i,
		&__icon {
			display: flex;
			align-items: center;


			b {
				font-size: 0.8rem;
			}
		}
	}

	&.-c-icon-centered-text {
		justify-content: flex-start;


		> span {
			flex: 1 0 0%;

			margin-left: -20px;

			text-align: center;
		}
	}

	&.-icon-challenge {
		#{$c}__icon {
			&:before {
				content: '\e83b';
			}
		}
	}

	&.-icon-challenge-return {
		#{$c}__icon {
			font-size: 20px;


			&:before {
				content: '\e83a';

				position: relative;
				top: -1px;
				left: 2px;
			}
		}
	}

	&.-icon-select {
		#{$c}__icon {
			&:before {
				content: '\e841';
			}
		}
	}


	// component
	.c-notification {
		position: absolute;
		top: -3px;
		left: -2px;

		z-index: 3;


		&.-c-top-right {
			top: -10px;
			right: -10px;
			left: auto;
		}
	}
}
