.c-difficulty {
	position: relative;

	display: inline-flex;
	align-items: center;
	justify-content: center;

	min-width: 50px;
	height: 16px;
	padding: 3px 5px;

	border: 1px solid #aaa;
	border-radius: 3px;

	background-color: #332515;
	color: #aaa;


	&:before {
		content: '';

		position: absolute;
		top: -3px;
		left: -3px;
		right: -3px;
		bottom: -3px;

		border: 2px solid #1b1107;
		border-radius: 4px;
	}

	&:after {
		content: '';

		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;

		border: 1px solid #000;
		border-radius: 3px;
	}


	span {
		font-size: 0.55rem;
		text-transform: uppercase;
		text-shadow: 1px 1px 1px #000;
	}


	// beginner
	&.-diff-1 {
		border-color: #AE8B65;

		color: #AE8B65;
	}

	// easy
	&.-diff-2 {
		border-color: #D08650;

		color: #D08650;
	}

	// medium
	&.-diff-3 {
		border-color: #DA6A1A;

		color: #DA6A1A;
	}

	// hard
	&.-diff-4 {
		border-color: #DD3D20;

		color: #DD3D20;
	}

	// extreme
	&.-diff-5 {
		border-color: #F0116E;

		color: #F0116E;
	}

	// impossible
	&.-diff-6 {
		border-color: #E211C8;

		color: #E211C8;
	}

	// forbidden
	&.-diff-7 {
		border-color: #C800FF;

		color: #C800FF;
	}
}