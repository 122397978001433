.c-ele {
	position: relative;

	display: flex;
	align-items: center;
	justify-content: center;

	width: 16px;
	height: 16px;

	background-color: $c-ele-background-color;
	border: 1px solid;
	border-color: $c-ele-fire-border-spotlight-color
				 $c-ele-fire-border-shade-color
				 $c-ele-fire-border-shade-color
				 $c-ele-fire-border-spotlight-color;


	&:before {
		content: '';

		position: absolute;
		top: -3px;
		left: -3px;
		right: -3px;
		bottom: -3px;

		border: 2px solid rgba($c-ele-outer-border-color, 0.7);
	}

	&:after {
		@extend %font-nm;

		content: '';

		font-size: 10px;
		line-height: 1.1;
		text-shadow: 1px 1px 0 $c-ele-icon-shadow-color;

		color: #fff;
	}


	&.-ele-1 {
		background-color: $c-ele-fire-background-color;
		border-color: $c-ele-fire-border-spotlight-color
					 $c-ele-fire-border-shade-color
					 $c-ele-fire-border-shade-color
					 $c-ele-fire-border-spotlight-color;

		&:after {
			content: '\e829';

			text-shadow: 1px 1px 0 $c-ele-fire-icon-shadow-color;
		}
	}

	&.-ele-2 {
		background-color: $c-ele-wind-background-color;
		border-color: $c-ele-wind-border-spotlight-color
					 $c-ele-wind-border-shade-color
					 $c-ele-wind-border-shade-color
					 $c-ele-wind-border-spotlight-color;

		&:after {
			content: '\e808';

			text-shadow: 1px 1px 0 $c-ele-wind-icon-shadow-color;
		}
	}

	&.-ele-3 {
		background-color: $c-ele-lightning-background-color;
		border-color: $c-ele-lightning-border-spotlight-color
					 $c-ele-lightning-border-shade-color
					 $c-ele-lightning-border-shade-color
					 $c-ele-lightning-border-spotlight-color;

		&:after {
			content: '\e82d';

			text-shadow: 1px 1px 0 $c-ele-lightning-icon-shadow-color;
		}
	}

	&.-ele-4 {
		background-color: $c-ele-earth-background-color;
		border-color: $c-ele-earth-border-spotlight-color
					 $c-ele-earth-border-shade-color
					 $c-ele-earth-border-shade-color
					 $c-ele-earth-border-spotlight-color;

		&:after {
			content: '\e835';

			text-shadow: 1px 1px 0 $c-ele-earth-icon-shadow-color;
		}
	}

	&.-ele-5 {
		background-color: $c-ele-water-background-color;
		border-color: $c-ele-water-border-spotlight-color
					 $c-ele-water-border-shade-color
					 $c-ele-water-border-shade-color
					 $c-ele-water-border-spotlight-color;

		&:after {
			content: '\e81d';

			text-shadow: 1px 1px 0 $c-ele-water-icon-shadow-color;
		}
	}


	&.-size-s {
		width: 13px;
		height: 13px;


		&:after {
			font-size: 8px;
		}
	}

	&.-size-m {
		width: 18px;
		height: 18px;


		&:after {
			font-size: 12px;
		}
	}

	&.-size-l {
		width: 24px;
		height: 24px;


		&:after {
			font-size: 16px;
		}
	}

	&.-size-xl {
		width: 32px;
		height: 32px;


		&:after {
			font-size: 20px;
		}
	}

	&.-border-thin {
		&:before {
			top: -2px;
			left: -2px;

			border-width: 1px 2px 2px 1px;
			border-radius: 2px 2px 3px 2px;
		}
	}
}