.c-page-form {
	&__title {
		border-bottom: 1px solid #403315;

		font-size: 0.9rem;

		color: #f2e0c0;
	}

	&__options {
		margin-bottom: 20px;

		font-size: 0.9rem;

		color: #f2e0c0;
	}

	&__checkbox {
		font-size: 0.7rem;

		color: #CAB088;


		label {
			display: block;
			margin: 3px 0;
		}

		input[type=checkbox] {
			position: relative;
			bottom: 1px;

			margin-right: 2px;

			vertical-align: middle;
		}
	}

	&__buttons {
		display: flex;
		flex-flow: row wrap;

		padding: 10px 8px;

		font-size: 20px;

		color: #735e45;


		// component
		.c-filter-button {
			height: auto;
			margin-right: -2px;
			padding: 8px 12px;
		}

		// component
		.c-rank {
			margin: 0 2px;
		}
	}

	&__button {
		margin: 10px;

		text-align: center;


		// component
		.c-button {

		}
	}
}