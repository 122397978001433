.c-shop-box {
	$c: &;

	@extend %c-box;

	flex-flow: row nowrap;

	min-width: 0;
	padding: 8px;

	text-shadow: 1px 1px 1px #000;


	&__icon {
		flex: 0 0 30px;

		width: 30px;
		margin: 0 5px 0 5px;

		font-size: 24px;
		text-align: center;

		color: #D6B186;
	}

	&__item {
		flex: 1 1 auto;

		position: relative;

		display: flex;
		align-items: center;

		margin: 0 5px;


		// component
		.c-item {
			display: flex;

			width: 250px;
		}

		// component
		.c-rank {
			position: absolute;
			right: 0;
		}
	}

	&__info {
		// .c-shop-box-info
	}

	&__max {
		flex: 0 1 auto;

		display: flex;
		align-items: center;

		font-size: 0.8rem;
		text-align: center;
		text-shadow: 1px 1px 1px #000;


		span {
			padding-right: 2px;

			font-size: 0.65rem;
			text-transform: uppercase;

			color: #AE8B65;
		}

		b {
			font-size: 0.8rem;

			color: #bb9d7b;
		}
	}

	&__ninjas {
		flex: 0 0 100px;

		display: flex;
		align-items: center;

		width: 100px;

		margin: -5px 0 -4px 5px;


		// component
		.c-card {
			margin-right: -10px;

			z-index: 1;


			&:first-child {
				margin-left: -15px;
			}

			// 4 or more cards, narrow them
			&:first-child:nth-last-child(n+4),
			&:first-child:nth-last-child(n+4) ~ .c-card {
				margin-right: -20px;
			}
		}
	}

	&__cost {
		flex: 0 0 auto;

		display: flex;
		align-items: center;

		height: 35px;
		margin: 0 5px 0 60px;


		// component
		.c-resource {
			width: 95px;
			min-width: 95px;


			// component
			.c-notification {
				position: absolute;
				top: -7px;
				right: 75px;

				// ie 11 somewhat fix
				min-width: 50px;


				span {
					white-space: nowrap;
				}
			}
		}

		// component
		.c-button {
			width: 110px;
			min-width: 110px;
			height: 35px;

			margin: 0 0 0 5px;


			span {
				flex: 1 0 0%;

				margin-left: -20px;

				text-align: center;
			}
		}
	}


	// component
	> .c-notification {
		position: absolute;
		top: -3px;
		left: 5px;

		z-index: 1;
	}
}


/**
 * Info section.
 */
.c-shop-box-info {
	position: relative;

	flex: 1 1 auto;

	min-width: 0;
	margin: 0 5px;

	text-shadow: 1px 1px 1px #000;


	&__name {
		position: relative;

		flex: 0 1 auto;

		min-width: 0;

		font-size: 1.1rem;
		line-height: 1.2;
		text-overflow: ellipsis;
		white-space: nowrap;

		color: #FFB56B;

		overflow: hidden;

		z-index: 1;
	}

	&__desc {
		margin-top: 3px;
		padding-bottom: 6px;

		font-size: 0.7rem;
		line-height: 1.0;

		color: #D6B186;
	}


	// component
	.c-underline {
		margin-top: -1px;
	}
}
