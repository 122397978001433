.c-help {
	position: relative;

	width: 400px;
	min-height: 100px;

	padding: 15px;

	background-color: #000;

	z-index: 10001;


	&:before {
		content: '';

		position: absolute;
		top: -10px;
		left: -10px;
		right: -10px;
		bottom: -10px;

		border: 10px solid rgba(#000, 0.2);
		border-radius: 4px;
	}


	&__outer {
		border-right: 2px solid #906437;

		text-shadow: 1px 1px 1px #000;

		background-color: #332517;
	}

	&__head {
		padding: 4px 10px;
		border-bottom: 2px solid #674625;

		text-align: center;

		background-color: #906437;


		span {
			font-size: 0.9rem;
			line-height: 1.1;

			color: #FFE5C4;
		}
	}

	&__inner {
		position: relative;
		padding: 10px 20px 15px 20px;

		border-top: 2px solid #000;
		border-right: 2px solid #000;

		font-size: 0.75rem;


		&.-c-loading {
			min-height: 100px;
		}
	}

	&__pic {
		position: relative;

		float: right;

		width: 54px;
		height: 60px;

		margin: -30px -30px 5px 5px;

		background: 0 0 no-repeat;
	}

	&__msg {
		position: relative;

		display: table;


		> h3 {
			margin: 0 0 -8px 0;

			font-size: 0.8rem;

			color: #F9DFBE;
		}

		> p {
			margin: 10px 0;
			padding: 0;

			color: #FFD39A;

			> b {
				color: #FDAA29;
			}

			> strong {
				color: #FDD129;
			}

			> em {
				text-decoration: underline;

				color: #FDD129;

				cursor: pointer;
			}
		}

		a {
			text-decoration: underline;

			color: #F1B26F;
		}


		&.-padding-text {
			padding: 10px 0;
		}
	}

	&__close {
		position: absolute;
		top: 5px;
		left: 5px;

		display: none;

		color: #ff0000;
		text-shadow: 1px 1px 1px #000;

		cursor: pointer;
	}


	&.-border-thin {
		padding: 4px;
	}
}



.c-help-ninja {
	display: flex;

	margin: 10px;


	&__card {

	}

	&__info {

		// component
		.c-page-list {
			margin: 0 0 0 -15px;
		}
	}

	&__genjutsu {
		margin: 10px 0 10px 20px;


		// component
		.c-item {
			width: 110px;

			margin: 6px 0 0 3px;
		}
	}
}
