/*
 * Colors from: http://www.color-blindness.com/color-name-hue/
 * Pattern: $color-name
 *      Add -2 to variable if same name
 */

// general
$color-black: #000;
$color-white: #fff;
$color-white-smoke: #eee;
$color-dark-gray: #aaa;
$color-silver: #bbb;
$color-nobel: #999;
$color-suva-grey: #888;
$color-tangerine-yellow: #ffcc00;
$color-red: #ff0000;

// misc
$color-persian-red: #d02c2c;
$color-parsley: #2F5C35;
$color-emerald: #5AC567;
$color-milano-red: #9D3131;

// akatsuki dawn
$color-morocco-brown: #44321e;
$color-van-cleef: #513c36;
$color-seal-brown: #19120c;
$color-seal-brown-2: #1F170E;
$color-cocoa-brown: #261c13;
$color-cocoa-brown-2: #31241c;
$color-bakers-chocolate: #380909;
$color-clinker: #483426;
$color-woodburn: #423127;
$color-bistre: #2F2317;
$color-deep-bronze: #523C2B;
$color-kabul: #6b594f;
$color-brown-pod: #352719;
$color-brown-pod-2: #291e14;
$color-roman-coffee: #816A57;
$color-bracken: #533d23;

$color-peach-puff: #FFD7B4;
$color-raffia: #D8B79A;
$color-pale-taupe: #BD9D78;
$color-negroni: #EDC896;

// footer
$color-cream-can: #F1BC43;
$color-alpine: #A28239;
$color-fire-bush: #DAA144;
$color-hot-curry: #775F25;
$color-roti: #BD9945;
$color-teak: #AB9258;



// ranks
$color-sorrell-brown: #967c60;
$color-putty: #cfb46c;
$color-apache: #cfad54;
$color-apache-2: #d0a253;
$color-whiskey-sour: #d09753;
$color-whiskey-sour-2: #d08853;
$color-japonica: #d07c53;
$color-japonica-2: #d07053;
$color-valencia: #d06253;
$color-mandy: #d05353;
$color-cabaret: #d05376;
$color-mulberry: #d0539f;
$color-fuchsia: #d053c9;
$color-medium-orchid: #c353d0;
$color-medium-orchid-2: #b153d0;
$color-medium-orchid-3: #9d53d0;
$color-amethyst: #8853d0;
$color-slate-blue: #7353d0;

$color-barley-corn: #AF9B65;
$color-tallow: #A99A71;
$color-husk: #B7994B;
$color-husk-2: #B38C48;
$color-luxor-gold: #AD7E45;
$color-tuscany: #A76D43;
$color-tuscany-2: #A76443;
$color-tuscany-3: #A95B44;
$color-apple-blossom: #AB5044;
$color-roof-terracotta: #A54242;
$color-night-shadz: #A2415C;
$color-royal-heath: #A7437F;
$color-royal-heath-2: #A2419D;
$color-deep-lilac: #9C41A7;
$color-vivid-violet: #8C41A5;
$color-royal-purple: #7C42A5;
$color-royal-purple-2: #6A41A2;
$color-daisy-bush: #58409E;

// ele
$color-burnt-sienna: #f25b5a;
$color-sweet-pink: #ee8e8d;
$color-mandy-2: #c94d4d;
$color-milano-red-2: #a13938;

$color-medium-turquoise: #4dc1d2;
$color-french-pass: #aed6e0;
$color-boston-blue: #4ba2bc;
$color-hippie-blue: #4a8790;

$color-laser: #c5ba5a;
$color-mint-julep: #e0daa9;
$color-luxor-gold-2: #a39941;
$color-himalaya: #7c7537;

$color-reef-gold: #a98332;
$color-pavlova: #b8a984;
$color-harvest: #8c6f2a;
$color-raw-umber: #715314;

$color-picton-blue: #5694d4;
$color-echo-blue: #93abc6;
$color-steel-blue: #467cb5;
$color-lochmara: #32679d;

// exp
$color-seal-brown-3: #0a0805;
$color-deep-bronze-2: #55412a;
$color-spice: #635238;
$color-shadow: #836e4d;
$color-corn-harvest: #916c29;
$color-clay-creek: #88795e;
$color-soft-amber: #cdbda0;
$color-raffia-2: #D8BD9F;

$color-seal-brown-4: #0a0505;
$color-moccaccino: #552a2a;
$color-very-dark-brown: #633838;
$color-solid-pink: #834d4d;
$color-solid-pink-2: #885151;
$color-rose-taupe: #885e5e;
$color-careys-pink: #cda0a0;
$color-rose: #d89f9f;

// filter button
$color-cola: #3e2c24;
$color-almond-frost: #988980;
$color-seal-brown-5: #140b05;
$color-saddle: #5A4F48;
$color-cocoa-brown-3: #2A1C16;
$color-slugger: #44342C;
$color-silk: #BEAB9F;

$color-saddle-brown: #843B10;
$color-au-chico: #9A6259;
$color-caput-mortuum: #52251B;

$color-cape-palliser: #6e4730;
$color-sorrell-brown-2: #997660;
$color-indian-tan: #4C2E1C;

// option button
$color-chiccolato: #5F4130;
$color-derby: #F9DACB;
$color-cement: #886C5B;
$color-indian-tan-2: #42291A;

// resource
$color-paris-daisy: #FFE94C;
$color-spice-2: #674F3D;
$color-bakers-chocolate-2: #4F3A12;
$color-tyrian-purple: #120501;

$color-golden-poppy: #e9b500;

$color-screamin-green: #4cff5b;

$color-mango-tango: #EB6A00;

$color-very-light-grey: #C8C8C8;

$color-tumbleweed: #DCA87B;

$color-dark-salmon: #E4A06F;

$color-tosca: #774242;
$color-falu-red: #611313;
$color-tyrian-purple-2: #290101;

$color-malachite: #21e432;
$color-hunter-green: #3d6740;
$color-crusoe: #124f17;
$color-dark-green: #011205;
$color-emerald-2: #53d058;

// notification
$color-fire-engine-red: #b31616;
$color-fuzzy-wuzzy-brown: #c85858;
$color-falu-red-2: #871111;
$color-falu-red-3: #6e0d0d;
$color-mahogny: #be3737;

// pos
$color-dark-sea-green: #8eb68f;
$color-echo-blue-2: #a0a6cd;
$color-charm: #cc7e7e;
$color-lilac: #cda0c2;

// bar
$color-bakers-chocolate-3: #38290D;
$color-mango-tango-2: #D68B00;
$color-olive: #956000;
$color-cloudy: #b5aba2;

$color-la-rioja: #cfc808;
$color-olive-2: #908c06;

$color-harlequin: #32cf08;
$color-islamic-green: #239006;

$color-pacific-blue: #009CE3;
$color-cerulean: #006C9F;
$color-light-cyan: #CBEDFF;

$color-scampi: #6c6ca6;
$color-victoria: #525288;
$color-tropical-blue: #c9c8f3;

$color-van-cleef-2: #583a3a;
$color-turkish-rose: #a66c6c;
$color-lotus: #885252;
$color-pink: #FFCBCB;

$color-fire-engine-red-2: #EA2411;
$color-venetian-red: #A41709;

$color-jacko-bean: #403729;
$color-birch: #3E3525;
$color-seal-brown-6: #1B130B;
$color-calico: #D6B985;
$color-sorrell-brown-3: #9A8763;
$color-rum-swizzle: #F1E9D4;

$color-seal-brown-7: #21180f;
$color-beaver: #92775b;
$color-tabacco-brown: #735b42;

// button
$color-forest-green: #428619;
$color-asparagus: #85A96D;
$color-verdun-green: #305E13;
$color-forest-green-2: #336d10;

$color-contessa: #BD6262;
$color-pohutukawa: #661717;
$color-flame-red: #842525;

$color-silver-2: #C0C0C0;
$color-dim-gray: #616161;

$color-bracken-2: #563826;

$color-kabul-2: #6B5D54;
$color-cocoa-brown-4: #2e201a;

// card
$color-shingle-fawn: #795936;
$color-quincy: #645243;

$color-seal-brown-8: #251c0b;
$color-shadow-2: #86744f;

$color-ce-soir: #A363AB;
