.c-avatar {
	$c: &;

	position: relative;

	height: 56px;
	width: 51px;

	border: 2px solid #795936;
	border-radius: 2px;

	background: #000;


	&:before {
		content: '';

		position: absolute;
		top: -3px;
		left: -3px;
		right: -4px;
		bottom: -4px;

		border-style: solid;
		border-color: #000;
		border-width: 1px 2px 2px 1px;
		border-radius: 4px;
	}


	&__pic {
		position: relative;
		margin: 1px;

		width: 45px;
		height: 50px;


		&:before {
			content: '';

			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;

			border: 1px solid rgba(#000, 0.5);

			z-index: 1;
		}
	}


	&.-size-s {
		height: 56px;
		width: 51px;


		#{$c}__pic {
			width: 45px;
			height: 50px;
		}
	}

	&.-size-m {

	}

	&.-size-l {

	}
}