.c-filter-button {
	position: relative;

	display: flex;
	justify-content: center;
	align-items: center;

	height: 25px;
	padding: 0 15px;

	background: $c-filter-button-background-color;
	color: $c-filter-button-color;
	border: 2px solid $c-filter-button-border-color;
	border-radius: 3px;

	text-align: center;
	text-shadow: 1px 1px 2px $shadow-color-strongest;

	cursor: pointer;


	&:before {
		content: "";

		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;

		border: 2px solid;
		border-color: $c-filter-button-border-inset-spotlight-color
					 $c-filter-button-border-inset-shade-color
					 $c-filter-button-border-inset-shade-color
					 $c-filter-button-border-inset-spotlight-color;
	}

	&:hover {
		background: $c-filter-button-hover-background-color;
		color: $c-filter-button-hover-color;
	}


	span {
		position: relative;
		top: -1px;

		max-width: 100%;
		padding: 0 1px;

		font-size: 0.65rem;
		text-shadow: 1px 1px 1px $shadow-color-strongest;
		text-overflow: ellipsis;

		overflow: hidden;
		white-space: nowrap;


		i {
			font-size: 9px;
		}
	}


	&__icon {
		margin: 0 5px 0 -5px;

		font-size: 0.9rem;
	}


	&.-c-active {
		background: $c-filter-button-active-background-color;
		color: $c-filter-button-active-color;

		&:before {
			border-color: $c-filter-button-active-border-inset-spotlight-color
						 $c-filter-button-active-border-inset-shade-color
						 $c-filter-button-active-border-inset-shade-color
						 $c-filter-button-active-border-inset-spotlight-color;
		}

		&:hover {
			background: $c-filter-button-active-hover-background-color;
			color: $c-filter-button-active-hover-color;
		}
	}

	&.-c-sel {
		background: $c-filter-button-sel-background-color;
		color: $c-filter-button-sel-color;

		&:before {
			border-color: $c-filter-button-sel-border-inset-spotlight-color
						 $c-filter-button-sel-border-inset-shade-color
						 $c-filter-button-sel-border-inset-shade-color
						 $c-filter-button-sel-border-inset-spotlight-color;
		}

		&:hover {
			background: $c-filter-button-sel-hover-background-color;
			color: $c-filter-button-sel-hover-color;
		}
	}


	&.-size-m {
		height: 30px;

		border-radius: 4px;


		&:before {
			border-radius: 2px;
		}


		span {
			font-size: 0.7rem;
		}
	}
}