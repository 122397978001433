/**
 * Variables.
 */
$graph: '.c-ninja-graph';





.c-ninja-graph {
	$c: &;

	position: relative;


	canvas {
		position: absolute;
	}


	&.-size-m {
		width: 200px;
		height: 140px;


		canvas {
			top: 20px;
			left: 40px;

			width: 115px;
			height: 102px;

			background: url('#{$gfx-path}/themes/#{$theme}/shared/graph_ninja_100px.png') 0 0 no-repeat;
		}
	}

	&.-size-l {
		width: 260px;
		height: 220px;


		canvas {
			top: 20px;
			left: 30px;

			width: 194px;
			height: 170px;

			background: url('#{$gfx-path}/themes/#{$theme}/shared/graph_ninja_200px.png') 0 0 no-repeat;
		}
	}
}


/**
 * Stat section.
 */
.c-ninja-graph-stat {
	$stat: &;

	position: absolute;

	line-height: 1;
	text-shadow: 1px 1px 1px #000;


	&__value {
		font-size: 0.8rem;

		color: #fff;
	}

	&__growth {
		font-size: 0.6rem;

		color: #FFCC94;
	}

	&__name {
		position: relative;
		top: -1px;

		font-size: 0.6rem;

		color: #B9916F;
	}


	&.-stat-tai {
		text-align: right;
	}

	&.-stat-nin {

	}

	&.-stat-buki {
		width: 60px;
	}

	&.-stat-sta {
		width: 60px;

		text-align: right;
	}

	&.-stat-ele {
		width: 60px;
	}

	&.-stat-gen {
		top: 113px;
		right: 113px;

		text-align: right;
	}


	#{$graph}.-size-m & {
		#{$stat}__name {
			top: -2px;
		}

		&.-stat-tai {
			top: 7px;
			right: 110px;
		}

		&.-stat-nin {
			top: 7px;
			left: 113px;
		}

		&.-stat-buki {
			top: 55px;
			left: 141px;
		}

		&.-stat-sta {
			top: 55px;
			right: 145px;
		}

		&.-stat-ele {
			top: 107px;
			left: 113px;


			#{$stat}__name {
				top: 4px;
			}
		}

		&.-stat-gen {
			top: 107px;
			right: 113px;


			#{$stat}__name {
				top: 4px;
			}
		}
	}

	#{$graph}.-size-l & {
		&.-stat-tai {
			top: 7px;
			right: 159px;
		}

		&.-stat-nin {
			top: 7px;
			left: 159px;
		}

		&.-stat-buki {
			top: 90px;
			left: 206px;
		}

		&.-stat-sta {
			top: 90px;
			right: 210px;
		}

		&.-stat-ele {
			top: 174px;
			left: 159px;


			#{$stat}__name {
				top: 3px;
			}
		}

		&.-stat-gen {
			top: 174px;
			right: 159px;


			#{$stat}__name {
				top: 3px;
			}
		}
	}


	#{$graph}.-c-icons & {
		margin-right: 6px;
		padding: 0 18px 0 0;


		&__growth {
			display: block;
		}

		&__name {
			display: none;
		}


		&:before {
			@extend %font-nm;

			position: absolute;
			top: 4px;

			font-size: 16px;

			color: #9C785A;
		}

		&.-stat-tai {
			&:before {
				content: '\e85d';

				right: 0;
			}
		}

		&.-stat-nin {
			margin: 0;
			padding: 0 0 0 17px;


			&:before {
				content: '\e852';

				left: 0;
			}
		}

		&.-stat-buki {
			margin: 0;
			padding: 0 0 0 16px;


			&:before {
				content: '\e83b';

				left: 0;
			}
		}

		&.-stat-sta {
			&:before {
				content: '\e85a';

				right: 0;
			}
		}

		&.-stat-ele {
			margin: 0;
			padding: 0 0 0 17px;


			&:before {
				content: '\e829';

				left: 0;
			}
		}

		&.-stat-gen {
			&:before {
				content: '\e800';

				right: 0;
			}
		}
	}

	#{$graph}.-display-names & {
		&__name {
			display: block;
		}
	}
}