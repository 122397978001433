.c-rank {
	$c: &;

	position: relative;

	display: flex;
	justify-content: center;


	&:before {
		@extend %font-nm;

		content: '\e806';

		font-size: 7px;
		line-height: 1.1;
		text-shadow: 1px 1px 0 $shadow-color-strongest;
	}

	b {
		position: absolute;
		top: 1px;
		right: 0;
		left: 0;

		font-family: verdana, sans-serif;
		font-weight: normal;
		font-style: normal;
		font-size: 8px;
		text-align: center;
		text-shadow: 1px 1px 1px $shadow-color-strongest;

		color: $c-rank-difficulty-color;
	}


	&.-rank-0 {
		@extend %rank-ribbon;


		&:before {
			color: $c-rank-0-color;
		}


		&.-c-inverse {
			&:before {
				color: rgba($c-rank-0-inverse-color, 0.8);
			}
		}
	}

	&.-rank-1 {
		@extend %rank-medal;


		&:before {
			color: $c-rank-1-color;
		}


		&.-c-inverse {
			&:before {
				color: rgba($c-rank-1-inverse-color, 0.8);
			}
		}
	}

	&.-rank-2 {
		@extend %rank-medal;


		&:before {
			color: $c-rank-2-color;
		}


		&.-c-inverse {
			&:before {
				color: rgba($c-rank-2-inverse-color, 0.8);
			}
		}
	}

	&.-rank-3 {
		@extend %rank-medal;


		&:before {
			color: $c-rank-3-color;
		}


		&.-c-inverse {
			&:before {
				color: rgba($c-rank-3-inverse-color, 0.8);
			}
		}
	}

	&.-rank-4 {
		@extend %rank-medal;


		&:before {
			color: $c-rank-4-color;
		}


		&.-c-inverse {
			&:before {
				color: rgba($c-rank-4-inverse-color, 0.8);
			}
		}
	}

	&.-rank-5 {
		@extend %rank-fan;


		&:before {
			color: $c-rank-5-color;
		}


		&.-c-inverse {
			&:before {
				color: rgba($c-rank-5-inverse-color, 0.8);
			}
		}
	}

	&.-rank-6 {
		@extend %rank-fan;


		&:before {
			color: $c-rank-6-color;
		}


		&.-c-inverse {
			&:before {
				color: rgba($c-rank-6-inverse-color, 0.8);
			}
		}
	}

	&.-rank-7 {
		@extend %rank-fan;


		&:before {
			color: $c-rank-7-color;
		}


		&.-c-inverse {
			&:before {
				color: rgba($c-rank-7-inverse-color, 0.8);
			}
		}
	}

	&.-rank-8 {
		@extend %rank-fan;


		&:before {
			color: $c-rank-8-color;
		}


		&.-c-inverse {
			&:before {
				color: rgba($c-rank-8-inverse-color, 0.8);
			}
		}
	}

	&.-rank-9 {
		@extend %rank-fox;


		&:before {
			color: $c-rank-9-color;
		}


		&.-c-inverse {
			&:before {
				color: rgba($c-rank-9-inverse-color, 0.8);
			}
		}
	}

	&.-rank-10 {
		@extend %rank-fox;


		&:before {
			color: $c-rank-10-color;
		}


		&.-c-inverse {
			&:before {
				color: rgba($c-rank-10-inverse-color, 0.8);
			}
		}
	}

	&.-rank-11 {
		@extend %rank-fox;


		&:before {
			color: $c-rank-11-color;
		}


		&.-c-inverse {
			&:before {
				color: rgba($c-rank-11-inverse-color, 0.8);
			}
		}
	}

	&.-rank-12 {
		@extend %rank-fox;


		&:before {
			color: $c-rank-12-color;
		}


		&.-c-inverse {
			&:before {
				color: rgba($c-rank-12-inverse-color, 0.8);
			}
		}
	}

	&.-rank-13 {
		@extend %rank-moon;


		&:before {
			color: $c-rank-13-color;
		}


		&.-c-inverse {
			&:before {
				color: rgba($c-rank-13-inverse-color, 0.8);
			}
		}
	}

	&.-rank-14 {
		@extend %rank-moon;


		&:before {
			color: $c-rank-14-color;
		}


		&.-c-inverse {
			&:before {
				color: rgba($c-rank-14-inverse-color, 0.8);
			}
		}
	}

	&.-rank-15 {
		@extend %rank-moon;


		&:before {
			color: $c-rank-15-color;
		}


		&.-c-inverse {
			&:before {
				color: rgba($c-rank-15-inverse-color, 0.8);
			}
		}
	}

	&.-rank-16 {
		@extend %rank-moon;


		&:before {
			color: $c-rank-16-color;
		}


		&.-c-inverse {
			&:before {
				color: rgba($c-rank-16-inverse-color, 0.8);
			}
		}
	}

	&.-rank-17 {
		@extend %rank-moon;


		&:before {
			color: $c-rank-17-color;
		}


		&.-c-inverse {
			&:before {
				color: rgba($c-rank-17-inverse-color, 0.8);
			}
		}
	}

	&.-rank-18 {
		@extend %rank-moon-complete;


		&:before {
			color: $c-rank-18-color;
		}


		&.-c-inverse {
			&:before {
				color: rgba($c-rank-18-inverse-color, 0.8);
			}
		}
	}


	&.-size-l {

	}


	&.-c-inverse {
		&:before {
			text-shadow: 1px 1px 1px #f4ead3,
						0 0 0 #000;
		}


		b {
			text-shadow: 0 0 1px $shadow-color-strongest;

			color: $c-rank-inverse-difficulty-color;
		}
	}
}


/**
 * Placeholder for shared rank details.
 */
%rank-ribbon {
	&:before {
		content: '\e83d';

		font-size: 20px;
	}


	b {
		top: 2px;
	}


	&.-size-l {
		&:before {
			font-size: 26px;
		}
	}
}

%rank-medal {
	&:before {
		content: '\e819';

		font-size: 20px;
	}


	b {
		top: 3px;
	}


	&.-size-l {
		&:before {
			font-size: 26px;
		}


		b {
			top: 5px;
		}
	}
}

%rank-fan {
	&:before {
		content: '\e867';

		font-size: 20px;
	}


	b {
		top: 4px;
	}


	&.-size-l {
		&:before {
			font-size: 24px;
		}
	}
}

%rank-fox {
	&:before {
		content: '\e838';

		font-size: 18px;
	}


	b {
		top: 2px;
	}


	&.-size-l {
		&:before {
			font-size: 24px;
		}


		b {
			top: 5px;
		}
	}
}

%rank-moon {
	&:before {
		content: '\e839';

		font-size: 17px;
	}


	b {
		top: 1px;
	}


	&.-size-l {
		&:before {
			font-size: 24px;
		}
	}
}

%rank-moon-complete {
	&:before {
		content: '\e83e';

		font-size: 17px;
	}


	b {
		top: 1px;
	}


	&.-size-l {
		&:before {
			font-size: 24px;
		}


		b {
			top: 2px;
		}
	}
}
