.c-bar {
	$c: &;

	position: relative;

	width: 100px;
	height: 16px;
	padding: 2px;

	border: 1px solid $c-bar-border-color; // 3C2E23
	border-radius: 2px;

	background: linear-gradient($c-bar-background-gradient-from-color, $c-bar-background-gradient-to-color);


	&:before {
		content: '';

		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;

		border: 2px solid $c-bar-border-inset-color;
		border-radius: 1px;
	}


	&__fill {
		position: relative;

		width: 0%;
		min-width: 6px;
		height: 100%;

		background-color: $c-bar-fill-background-color;

		transition: width 0.5s linear;


		&:after {
			content: '';

			position: absolute;
			left: 0;
			bottom: 0;

			width: 100%;

			border-left: 3px solid $c-bar-fill-border-inset-shade-color;
			border-bottom: 3px solid $c-bar-fill-border-inset-spotlight-color;
		}

		&:before {
			content: '';

			position: absolute;
			right: 0;
			top: 0;

			height: 100%;

			border-top: 3px solid $c-bar-fill-border-inset-shade-color;
			border-right: 3px solid $c-bar-fill-border-inset-spotlight-color;
		}
	}

	&__text {
		position: absolute;
		right: -5px;
		bottom: -3px;

		display: flex;
		align-items: center;

		height: 15px;

		font-weight: bold;
		text-align: right;
		text-shadow: 1px 1px 1px $c-bar-text-shadow-color,
					-1px 0 1px $c-bar-text-shadow-color;


		&.-align-bottom {
			bottom: -8px;
		}
	}

	&__text-cur {
		font-size: 0.7rem;

		color: $c-bar-text-cur-color;
	}

	&__text-max {
		margin-left: 2px;

		font-size: 0.625rem;

		color: $c-bar-text-max-color;
	}

	&__desc {
		position: absolute;
		right: 0;
		bottom: -14px;

		z-index: 1;


		span {
			padding: 0 2px 0 19px;

			font-size: 0.6rem;
			text-shadow: 1px 1px 1px #000;
		}


		&:before {
			@extend %font-nm;

			position: absolute;
			top: -1px;
			left: 0;

			font-size: 18px;
			text-shadow: 1px 1px 1px #000,
						-1px 1px 0px #000,
						1px 0px 0px #000;
		}
	}


	&.-border-hidden {
		padding: 0;

		border: 0;
		border-radius: 0;


		&:before {
			content: none;
		}


		#{$c}__text {
			right: 1px;
		}
	}

	&.-border-battle {
		width: auto;
		height: 26px;

		border: 2px solid #000;
		border-radius: 4px;

		z-index: 1;


		#{$c}__text {
			right: 1px;
		}

		#{$c}__text-cur {
			font-size: 0.8rem;
		}

		#{$c}__desc {
			span {
				text-shadow: none;
			}
		}
	}


	&.-type-we {
		#{$c}__fill {
			background: $c-bar-we-fill-background-color;

			&:after {
				border-bottom-color: $c-bar-we-fill-border-inset-spotlight-color;
				border-left-color: $c-bar-we-fill-border-inset-shade-color;
			}

			&:before {
				border-top-color: $c-bar-we-fill-border-inset-shade-color;
				border-right-color: $c-bar-we-fill-border-inset-spotlight-color;
			}
		}
	}

	&.-type-ae {
		#{$c}__fill {
			background: $c-bar-ae-fill-background-color;

			&:after {
				border-bottom-color: $c-bar-ae-fill-border-inset-spotlight-color;
				border-left-color: $c-bar-ae-fill-border-inset-shade-color;
			}

			&:before {
				border-top-color: $c-bar-ae-fill-border-inset-shade-color;
				border-right-color: $c-bar-ae-fill-border-inset-spotlight-color;
			}
		}
	}

	&.-type-ce {
		#{$c}__fill {
			background: #08bdcf;

			&:after {
				border-bottom-color: #067790;
				border-left-color: #08b3cf;
			}

			&:before {
				border-top-color: #08b3cf;
				border-right-color: #067790;
			}
		}
	}

	&.-type-genjutsu {
		#{$c}__fill {
			background: $c-bar-genjutsu-fill-background-color;

			&:after {
				border-bottom-color: $c-bar-genjutsu-fill-border-inset-spotlight-color;
				border-left-color: $c-bar-genjutsu-fill-border-inset-shade-color;
			}

			&:before {
				border-top-color: $c-bar-genjutsu-fill-border-inset-shade-color;
				border-right-color: $c-bar-genjutsu-fill-border-inset-spotlight-color;
			}
		}

		#{$c}__text-max {
			color: $c-bar-genjutsu-text-max-color;
		}

		#{$c}__desc {
			&:before {
				content: '\e800';

				text-shadow: -1px -1px 0 #000,
							1px -1px 0 #000;

				color: #7AC1FF;
			}


			span {
				color: #7AC1FF
			}
		}
	}

	&.-type-achievement {
		#{$c}__fill {
			background: $c-bar-achievement-fill-background-color;

			&:after {
				border-bottom-color: $c-bar-achievement-fill-border-inset-spotlight-color;
				border-left-color: $c-bar-achievement-fill-border-inset-shade-color;
			}

			&:before {
				border-top-color: $c-bar-achievement-fill-border-inset-shade-color;
				border-right-color: $c-bar-achievement-fill-border-inset-spotlight-color;
			}
		}

		#{$c}__text-max {
			color: $c-bar-achievement-text-max-color;
		}
	}

	&.-type-bloodline {
		border-color: $c-bar-bloodline-border-color;

		#{$c}__fill {
			background: $c-bar-bloodline-fill-background-color;

			&:after {
				border-bottom-color: $c-bar-bloodline-fill-border-inset-spotlight-color;
				border-left-color: $c-bar-bloodline-fill-border-inset-shade-color;
			}

			&:before {
				border-top-color: $c-bar-bloodline-fill-border-inset-shade-color;
				border-right-color: $c-bar-bloodline-fill-border-inset-spotlight-color;
			}
		}

		#{$c}__text-max {
			color: $c-bar-bloodline-text-max-color;
		}
	}

	&.-type-health {
		#{$c}__fill {
			background: $c-bar-health-fill-background-color;

			&:after {
				border-bottom-color: $c-bar-health-fill-border-inset-spotlight-color;
				border-left-color: $c-bar-health-fill-border-inset-shade-color;
			}

			&:before {
				border-top-color: $c-bar-health-fill-border-inset-shade-color;
				border-right-color: $c-bar-health-fill-border-inset-spotlight-color;
			}
		}

		#{$c}__text {
			top: -6px;
			bottom: auto;
		}

		#{$c}__text-max {
			color: $c-bar-health-text-max-color;
		}

		#{$c}__desc {
			top: -23px;
			bottom: auto;


			&:before {
				content: none;
			}


			span {
				color: #FF8682
			}
		}
	}

	&.-type-bukijutsu {
		border-color: $c-bar-bukijutsu-border-color;
		background: linear-gradient($c-bar-bukijutsu-background-gradient-from-color, $c-bar-bukijutsu-background-gradient-to-color);


		#{$c}__fill {
			background: $c-bar-bukijutsu-fill-background-color;

			&:after {
				border-bottom-color: $c-bar-bukijutsu-fill-border-inset-spotlight-color;
				border-left-color: $c-bar-bukijutsu-fill-border-inset-shade-color;
			}

			&:before {
				border-top-color: $c-bar-bukijutsu-fill-border-inset-shade-color;
				border-right-color: $c-bar-bukijutsu-fill-border-inset-spotlight-color;
			}
		}

		#{$c}__text-cur {
			color: $c-bar-bukijutsu-text-cur-color;
		}

		#{$c}__text-max {
			color: $c-bar-bukijutsu-text-max-color;
		}

		#{$c}__desc {
			span {
				color: #B19F7F;
			}

			&:before {
				content: '\e83b';

				color: #CEB486;
			}
		}
	}

	&.-type-bukijutsu-inactive {
		background: $c-bar-bukijutsu-inactive-background-color;


		#{$c}__fill {
			background: $c-bar-bukijutsu-inactive-fill-background-color;

			&:after {
				border-bottom-color: $c-bar-bukijutsu-inactive-fill-border-inset-spotlight-color;
				border-left-color: $c-bar-bukijutsu-inactive-fill-border-inset-shade-color;
			}

			&:before {
				border-top-color: $c-bar-bukijutsu-inactive-fill-border-inset-shade-color;
				border-right-color: $c-bar-bukijutsu-inactive-fill-border-inset-spotlight-color;
			}
		}
	}

	&.-type-themed-inactive {
		background: #251d17;


		#{$c}__fill {
			background-color: #725845;

			&:after {
				border-bottom-color: #5a4637;
				border-left-color: #725845;
			}

			&:before {
				border-top-color: #725845;
				border-right-color: #5a4637;
			}
		}
	}


	&.-size-l {
		width: 300px;
		height: 26px;


		#{$c}__text-cur {
			font-size: 1rem;
		}

		#{$c}__text-max {
			font-size: 0.9rem;
		}
	}
}
