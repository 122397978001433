.c-area-box {
	$c: &;

	position: relative;

	display: flex;
	align-items: flex-end;

	height: 120px;

	border: 2px solid #77624C;
	border-radius: 4px;

	background-color: #211507;

	cursor: pointer;


	&:before {
		content: '';

		position: absolute;
		top: -6px;
		left: -6px;
		right: -6px;
		bottom: -6px;

		border: 4px solid #0B0602;
		border-radius: 7px;
	}


	&__bg {
		position: absolute;
		top: 0;
		left: 0;

		width: 100%;
		height: 100%;

		border: 2px solid #000;
		border-radius: 3px;

		overflow: hidden;


		&:before {
			content: '';

			position: absolute;
			top: 0;
			right: -1px;
			bottom: 0;
			left: 0;

			background: linear-gradient(rgba(33,21,7,0) 50%, #211507);
		}


		img {
			width: 100%;
			height: auto;
		}


		&.-c-opacity {
			&:after {
				content: '';

				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;

				background-color: rgba(#2a1d11, 0.75);
			}
		}
	}

	&__info {
		// .c-area-box-info
	}

	&__goto {

		// component
		&.c-button {
			position: absolute;
			top: 10px;
			right: 10px;
		}
	}

	&__village-protector {
		position: absolute;
		top: -9px;
		right: -7px;

		width: 88px;
		height: 42px;

		background: url('#{$gfx-path}/village_protector.png') 0 0 no-repeat;

		z-index: 1;


		i {
			position: absolute;
			top: 4px;
			right: 0;
			left: 0;

			margin: 0 auto;

			font-size: 22px;
			text-align: center;
			text-shadow: 0 0 1px #fff;

			color: #666;
		}
	}

	&__stats {
		// .c-area-box-stats
	}


	// component
	.c-notification {
		position: absolute;
		top: -3px;
		left: 8px;

		margin-right: 8px;


		span {
			display: inline-block;

			margin-right: 3px;
		}
	}


	&:hover {
		border-color: #9B7750;
	}


	&.-color-village {
		border-color: #76716C;


		&:hover {
			border-color: #9B958F;
		}


		// section
		.c-area-box-info {
			&__name {
				color: #DCD1C6;
			}
		}
	}

	&.-color-bonus {
		border-color: #774D4C;


		&:hover {
			border-color: #AE6261;
		}


		#{$c}__bg {
			&:before {
				background: linear-gradient(rgba(33,21,7, 0) 10%, #211507);
			}
		}

		// section
		.c-area-box-info {
			&__name {
				color: #F5A7A1;
			}
		}
	}

	&.-color-area {
		border-color: #77624C;


		&:hover {
			border-color: #9B7750;
		}
	}

	&.-size-village {
		height: 60px;


		#{$c}__bg {
			&:before {
				background: linear-gradient(rgba(33,21,7,0) 10%, #211507);
			}


			img {
				margin-top: -25px;
			}
		}


		// section
		.c-area-box-info {

			&__name {
				font-size: 0.8rem;
			}
		}
	}

	&.-size-s {
		#{$c}__bg {
			img {
				width: auto;
				height: 100%;


				// wide size layout
				.l-content-container.-size-wide & {
					height: auto;
				}
			}
		}


		// page
		.c-area-box-info {
			margin-right: 10px;


			&__name {
				font-size: 0.85rem;
			}
		}
	}

	&.-c-text-inline {

		// page
		.c-area-box-info {
			&__name {
				display: inline-block;
			}


			// component
			.c-difficulty-estimate {
				display: inline-flex;
			}
		}
	}

	&.-c-hide {
		opacity: 0.3;
	}

	&.-height-low {
		height: 80px;


		#{$c}__bg {
			img {
				width: 100%;
				height: auto;
			}
		}
	}
}


/**
 * Info section.
 */
.c-area-box-info {
	$info: &;

	position: relative;

	width: 100%;
	margin: 5px 50px 5px 8px;

	text-shadow: 1px 1px 1px #000;


	&__name {
		padding: 0 8px 0 2px;

		font-size: 1.2rem;
		line-height: 1.2;

		color: #FFB56B;


		i {
			margin-left: 2px;
		}
	}

	&__desc {
		padding: 0 0 7px 2px;

		font-size: 0.75rem;
		line-height: 1.1;

		color: #E6CAAE;
	}


	// component
	.c-difficulty-estimate {
		display: flex;

		margin-left: 1px;

		line-height: 1.1;
	}

	// component
	.c-bar {
		width: auto;

		margin-top: 3px;
		margin-bottom: -5px;

		border-color: #3C2E23;
		border-bottom: 0;


		&__text {
			top: -8px;
			bottom: auto;
		}
	}


	&.-font-s {
		#{$info}__name {
			font-size: 0.85rem;
		}
	}

	&.-text-inline {
		#{$info}__name {
			display: inline-block;
		}


		// component
		.c-difficulty-estimate {
			display: inline-flex;
		}
	}
}



/**
 * Stats icons section.
 */
.c-area-box-stats {
	position: absolute;

	right: 2px;
	bottom: 2px;


	span {
		display: inline-block;
		width: 35px;
		padding: 0 1px;

		font-size: 0.55rem;
		text-shadow: 1px 1px 1px #000;

		color: #c9bfb2;
	}

	i {
		margin-right: 2px;

		font-size: 10px;

		color: #aaa197;
	}
}
