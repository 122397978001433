.c-online {
	display: flex;
	align-items: center;

	color: #569460;


	&:before {
		@extend %font-nm;

		content: '\e98d';

		font-size: 6px;
	}


	span {
		padding-left: 2px;

		font-size: 0.6rem;
		text-transform: uppercase;
		text-shadow: 1px 1px 1px #000;
	}


	// online
	&.-status-1 {
		color: #569460;
	}

	// afk
	&.-status-2 {
		color: #826d56;
	}

	// offline
	&.-status-0 {
		color: #a24a4a;
	}

	// inactive
	&.-status--1 {
		color: #4e3329;
	}
}