/**
 * Variables.
 */
$bottom: '.c-achievement-bottom';





.c-achievement {
	$c: &;

	position: relative;

	display: flex; // required for ie 11 flex bug

	width: 160px;
	min-height: 200px;

	border: 3px solid #695343;

	background-color: #343367;


	&__bg {
		position: absolute;
		top: 0;
		right: 0;

		width: 100%;
		height: 100%;

		border: 2px solid #000;

		overflow: hidden;


		img {
			width: 100%;

			filter: sepia(100%) hue-rotate(200deg);
			opacity: 0.2;
		}
	}

	&__inner {
		flex: 1 1 0%;

		position: relative;

		display: flex;
		flex-flow: column nowrap;

		min-height: inherit;
	}

	&__close {
		position: absolute;
		top: 10px;
		right: 10px;

		display: none;

		color: #ff0000;
		text-shadow: 1px 1px 1px #000;

		cursor: pointer;
	}

	&__content {
		flex: 1 1 auto;

		margin: 10px 8px;
	}

	&__text {
		position: relative;

		display: inline-block;
		padding: 4px 5px;

		line-height: 1;
		text-shadow: 1px 1px 1px #000;

		background-color: rgba(#000, 0.6);
		color: #8885c7;
	}

	&__title {
		padding: 3px 5px;

		font-size: 1rem;
		line-height: 1;

		color: #c9c8f3;


		b {
			font-size: 0.8rem;
			font-weight: 600;
		}
	}

	&__desc {
		margin-top: 4px;
		margin-bottom: 5px;

		font-size: 0.7rem;
	}

	&__notice {
		margin-right: 20px;
		padding: 3px 3px;

		font-size: 0.55rem;
		text-transform: uppercase;
	}

	&__bot {
		// c-achievement-bottom
	}


	&:hover {

		// module
		.m-levels-container {
			&__points {
				color: #706da9;
			}
		}
	}


	&.-c-complete {
		border-color: #8c94b5;
	}

	&.-c-popup {
		&:before {
			content: '';

			position: absolute;
			top: -5px;
			left: -5px;
			right: -5px;
			bottom: -5px;

			border: 2px solid rgba(#000, 0.7);
			border-top: 0;
		}

		&:hover {
			&:after {
				content: '';

				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;

				background-color: rgba(#000, 0.5);

				z-index: 2;
			}


			#{$c}__close {
				display: block;

				z-index: 3;
			}
		}
	}

	&.-color-empty {
		background-color: #45362a;


		#{$c}__text {
			color: #876952;
		}

		#{$c}__title {
			color: #ad8e77;
		}


		#{$bottom} {
			background-color: #0e0b09;
		}

		#{$bottom}__trophy {
			border-color: #0a0807;
			background-color: #1d1712;


			&:after {
				color: #7a5f4a;
			}
		}

		#{$bottom}__date {
			color: #554234;
		}


		&:hover {

			// module
			.m-levels-container {

				&__goal {
					color: #8e7460;
				}

				&__points {
					color: #6b5341;
				}
			}
		}
	}
}





/**
 * Achievements bottom section
 */
.c-achievement-bottom {
	$c: &;

	display: flex;
	flex-flow: column nowrap;

	min-height: 70px;

	background-color: #0b0a14;


	&__trophy {
		position: absolute;
		top: 27px;
		right: 2px;
		left: 2px;

		height: 7px;

		border-top: 1px solid #090910;
		border-bottom: 1px solid #090910;

		background-color: #1a1a2d;


		&:after {
			@extend %font-nm;

			content: '\e807';

			position: absolute;
			top: -10px;
			left: 3px;

			font-size: 16px;
			text-shadow: 1px 1px 1px #000;

			color: #6c6abf;
		}
	}

	&__levels {
		padding: 1px;
	}

	&__date {
		position: relative;

		margin-top: auto;
		padding: 5px;

		font-size: 0.55rem;
		line-height: 1.1;
		text-align: left;

		color: #595777;
	}

	&__rewards {
		position: absolute;
		top: 35px;
		right: 1px;

		display: flex;


		// component
		.c-item {
			margin-left: -10px;

			border-color: #23233a !important;

			z-index: 3;
		}
	}


	// module
	.m-levels-container {
		&.-type-themed-inactive {
			#{$c}__rewards {


				// component
				.c-item {
					border-color: #44362a !important;
				}
			}
		}
	}
}
