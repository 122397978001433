.c-village {
	$c: &;

	position: relative;

	display: flex; // required for ie 11 flex bug

	width: 155px;
	min-height: 130px;

	border: 2px solid #4e3c28;

	background-color: #372a1c;


	&__bg {
		position: absolute;
		top: 0;
		right: 0;

		width: 100%;
		height: 100%;

		border: 2px solid #21180f;

		overflow: hidden;


		img {
			width: 100%;

			filter: sepia(100%);
			opacity: 0.2;
		}
	}

	&__inner {
		flex: 1 1 0%;

		position: relative;

		display: flex;
		flex-flow: column nowrap;

		min-height: inherit;
	}

	&__name {
		flex: 0 0 auto;

		margin-top: 8px;

		font-size: 0.7rem;
		text-align: center;
		text-transform: uppercase;
		text-shadow: 1px 1px 1px #000;

		color: #92775b;
	}

	&__icon {
		flex: 0 0 auto;

		position: relative;
		top: -2px;

		text-align: center;


		i {
			font-size: 50px;
			text-shadow: 1px 1px 1px #000;

			color: #92775b;
		}
	}

	&__content {
		flex: 1 1 auto;

		margin: -10px 0 20px 0;
		padding: 10px;
	}

	&__difficulty {
		position: relative;

		padding: 0 25px 0 0;


		// component
		.c-difficulty {
			position: relative;

			margin-right: -25px;
		}
	}

	&__bot {
		background-color: #21180f;
	}


	&.-c-cur {
		border-color: #92775b;
		background-color: #423527;


		#{$c}__name {
			color: #b29777;
		}

		#{$c}__icon {
			color: #b29777;
		}


		// page
		.c-village-bar {
			&__title {
				color: #d6b985;
			}
		}
	}
}


.c-village-bar {
	position: relative;


	&__title {
		position: absolute;
		top: -15px;
		left: 1px;

		line-height: 1;
		text-shadow: 1px 1px 1px #000;

		color: #a08466;

		z-index: 1;


		i {
			position: absolute;
			top: 6px;
			left: 2px;

			font-size: 12px;
		}

		span {
			padding-left: 15px;

			font-size: 0.6rem;
		}
	}

	// component
	.c-bar {
		width: auto;
		height: 12px;

		margin: 8px 2px 2px 2px;

		background: #2d2418;//292015;//33271a;
	}
}