.c-match-box {
	$c: &;

	@extend %c-box;


	flex-flow: row nowrap;
	align-items: center;
	justify-content: center;

	height: 43px;

	text-shadow: 1px 1px 1px #000;


	&__details {
		flex: 1 0 auto;

		position: relative;

		display: flex;
		align-items: center;
		justify-content: center;

		width: 100%;

		height: 39px;
	}

	&__extra {
		position: relative;

		flex: 1 0 auto;

		display: flex;
		justify-content: center;

		width: 100%;
		padding: 5px 0;

		border-top: 1px solid #674e3b;

		background-color: #4c3421;


		&.-c-hide {
			display: none;
		}
	}

	&__bg {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;

		display: flex;
		align-items: center;
		justify-content: flex-end;

		width: 400px;
		padding: 0 8px;

		background-position: 50% 50%;
		background-repeat: no-repeat;
		background-color: #000;

		overflow: hidden;


		&:before {
			content: '';

			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;

			background: linear-gradient(to left, rgba(#4c3422, 0.75), #4c3422 100%);
		}


		b {
			font-size: 0.6rem;
			text-shadow: 1px 1px 1px #000;

			color: #8c775e;

			z-index: 1;
		}

		i {
			margin-left: 3px;
		}
	}

	&__village {
		flex: 0 0 25px;
		width: 25px;

		margin: 0 15px 0 15px;

		font-size: 18px;
		text-align: center;

		color: #A68767;


		&.-c-positioned {
			position: absolute;
			right: 0;


			&:first-child {
				right: auto;
				left: 0;
			}
		}
	}

	&__status {
		position: relative;

		flex: 0 0 30px;
		width: 30px;

		margin: 0 5px 0 5px;
		padding: 2px 0;

		font-size: 18px;
		text-align: center;

		color: #A68767;


		&:before {
			@extend %font-nm;
		}


		&.-status-yes {
			color: #1ccc3a;


			&:before {
				content: '\e841';
			}
		}

		&.-status-no {
			color: #F2571F;


			&:before {
				content: '\e871';
			}
		}
	}

	&__penalty {
		position: relative;

		flex: 0 0 20px;
		width: 20px;

		margin-right: 10px;


		// component
		&.c-pow {
			align-items: flex-start;


			&:before {
				font-size: 10px;
			}


			span {
				right: 2px;
				bottom: 3px;

				font-size: 0.7rem;
			}
		}
	}

	&__vs {
		position: relative;
		top: -2px;

		margin: 0 20px;

		font-size: 1.2rem;
		font-weight: 700;

		color: #b79167;

		cursor: pointer;


		&.-c-active {
			color: #F19835;
		}
	}

	&__winner {
		position: absolute;
		top: -5px;

		width: 100px;

		font-size: 0.8rem;
		font-weight: 700;

		color: #F19835;

		z-index: 2;


		&.-side-left {
			left: -130px;

			text-align: right;
		}

		&.-side-right {
			right: -130px;
		}
	}


	&.-c-column {
		flex-flow: column nowrap;

		height: auto;
		min-height: 35px;
	}


	// non-first child elements
	> .c-match-box-score ~ .c-match-box-score {
		border-color: #5f412b;
	}


	// component
	.c-user-box {
		$userbox: '.c-user-box';

		width: 200px;

		background-color: transparent;


		&__inner {
			margin-right: 10px;
		}


		&.-c-reverse {
			&.-size-xs {
				#{$userbox}__inner {
					margin-left: 10px;
				}
			}
		}

		&.-size-xs {
			&:hover {
				z-index: 3;
			}
		}
	}


	&.-c-column {
		flex-flow: column nowrap;

		height: auto;
		min-height: 35px;
	}

	&.-width-m {
		min-width: 550px;
	}
}



.c-match-box-score {
	justify-content: center;

	padding: 5px 0;

	border-top: 1px solid #674e3b;


	&__score {
		margin: 0 25px;

		font-size: 0.75rem;
		font-weight: 700;
		text-align: center;

		color: #A88B68;

		z-index: 1;


		&.-c-win {
			color: #DABB9D;
		}
	}

	&__center {
		position: relative;

		flex: 0 0 auto;

		min-width: 45px;

		font-size: 0.675rem;
		text-align: center;

		color: #A88B68;


		b {
			color: #bd9f7a;
		}
	}

	&__replay {
		position: absolute;
		top: -3px;
		left: 0;
		right: 0;

		flex: 0 0 30px;
		width: 30px;

		margin: 0 auto;
		padding: 2px 0;

		font-size: 14px;
		text-align: center;

		color: #A68767;

		cursor: pointer;

		z-index: 1;


		&:before {
			@extend %font-nm;

			content: '\e81b';
		}


		&:hover {
			color: #DABB9D;
		}
	}
}
