.c-item {
	$c: &;

	/**
	 * Default values are for -size-s modifier.
	 */

	position: relative;

	display: inline-flex;
	flex-flow: row nowrap;
	align-items: center;

	height: 24px;

	border: 2px solid #795936;
	border-radius: 2px;

	background-color: #3f2a19;

	cursor: pointer;


	&:before {
		content: '';

		position: absolute;
		top: -3px;
		right: -4px;
		bottom: -4px;
		left: -3px;

		border-style: solid;
		border-color: #050302;
		border-width: 1px 2px 2px 1px;
		border-radius: 4px;
	}

	&:after {
		content: '';

		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;

		border: 2px solid #2b1d11;
	}


	&__icon {
		position: relative;

		flex: 0 0 auto;

		display: flex;
		align-items: center;
		justify-content: center;

		width: 18px;
		height: 18px;
		margin: 1px;

		border: 1px solid;
		border-color: #73583c #43301e #43301e #73583c;

		color: #a68b69;
		background-color: #513b26;

		z-index: 1;


		&:before {
			@extend %font-nm;

			content: none;

			font-size: 12px;
			text-shadow: 1px 1px 0 #000;
		}

		&:after {
			content: '';

			position: absolute;
			top: -1px;
			right: -3px;
			bottom: -1px;

			border-right: 2px solid #2b1d11;
		}
	}

	&__pic {
		position: relative;

		flex: 0 0 18px;

		display: flex;
		align-items: center;

		width: 18px;
		height: 18px;
		margin: 1px 0 1px 2px;

		background-color: #513b26;

		z-index: 1;


		img {
			width: 16px;
			height: 16px;
		}
	}

	&__name {
		position: relative;

		flex: 1 1 auto;
		padding: 0 7px 0 5px;

		font-size: 0.65rem;
		font-weight: 700;
		text-align: left;
		text-overflow: ellipsis;
		text-shadow: 1px 1px 0 #000;
		white-space: nowrap;

		color: #a68b69;

		overflow: hidden;


		em {
			margin-right: 4px;
		}
	}

	&__roll {
		position: relative;

		flex: 0 0 40px;

		padding: 0 10px 0 2px;

		font-size: 0.8rem;
		text-align: right;
		text-shadow: 1px 1px 0 #000;

		color: #b7b7b7
	}

	&__drop {
		position: relative;

		flex: 0 0 auto;

		padding: 0 5px 0 3px;

		font-size: 0.6rem;
		text-align: right;
		text-shadow: 1px 1px 0 #000;

		color: #bbb;


		b {
			padding-right: 1px;

			color: #ddd;
		}
	}

	&__amount {
		position: absolute;
		right: 0;
		bottom: 0;

		padding: 0 2px 1px 4px;

		border-radius: 4px 0 0 0;

		font-size: 0.7rem;
		text-align: right;

		color: #fff;
		background-color: #19120c;

		z-index: 1;
	}

	&__icons {
		position: relative;

		flex: 0 0 auto;

		display: flex;
		align-items:center;
		justify-content: flex-end;

		background-color: #000;

		z-index: 1;


		&:before {
			content: '';

			position: absolute;
			top: 0;
			left: -1px;
			bottom: 0;

			width: 1px;

			background-color: rgba(#000, 0.5);
		}


		i {
			width: 20px;

			font-size: 14px;
			text-align: center;

			color: #ECE3D6;
		}
	}


	&.-size-m {
		#{$c}__name {
			//top: -1px;

			font-size: 0.7rem;
			line-height: 1.8;
		}
	}

	&.-size-l {
		#{$c}__name {
			//top: -1px;

			font-size: 0.7rem;
			line-height: 1.8;
		}
	}

	&.-size-btn {
		#{$c}__icon {
			&:after {
				content: none;
			}
		}

		#{$c}__name {
			display: none;
		}


		&.-size-m {
			height: 40px;


			#{$c}__pic {
				flex-basis: 34px;

				width: 34px;
				height: 34px;
				margin: 1px 0 1px 2px;


				img {
					width: 32px;
					height: 32px;
				}
			}

			#{$c}__icon {
				width: 34px;
				height: 34px;


				&:before {
					font-size: 22px;
				}
			}
		}

		&.-size-l {
			height: 72px;


			#{$c}__pic {
				flex-basis: 66px;

				width: 66px;
				height: 66px;
				margin: 1px 0 1px 2px;


				img {
					width: 64px;
					height: 64px;
				}
			}
		}

		&.-size-low {
			height: 24px;


			#{$c}__pic {
				height: 16px;

				overflow: hidden;
			}
		}
	}

	&.-width-auto {
		width: auto;


		#{$c}__name {
			flex-basis: auto;
		}
	}

	&.-width-drop {
		#{$c}__name {
			display: none;
		}

		#{$c}__drop {
			flex-grow: 1;
		}
	}

	&.-text-overflow {
		#{$c}__name {
			flex-basis: auto;
		}
	}
}
