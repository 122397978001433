.c-levelup {
	$c: &;

	position: relative;

	width: 190px;

	border: 3px solid #513C36;
	border-radius: 2px;

	background-color: #261C13;


	&:before {
		content: '';

		position: absolute;
		top: -2px;
		left: -5px;
		right: -5px;
		bottom: -5px;

		border: 2px solid rgba(#000, 0.3);
		border-top: 0;
	}


	&__bg {
		position: absolute;
		top: 0;
		right: 0;

		width: 100%;
		height: 100%;

		border: 2px solid #291f1c;

		overflow: hidden;


		&:before {
			content: '';

			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;

			border: 2px solid rgba(#000, 0.3);

			z-index: 1;
		}


		img {
			min-width: 180px;
			height: 100%;
			min-height: 200px;

			opacity: 0.4;
		}
	}

	&__inner {
		position: relative;

		width: 100%;
		height: 100%;

		overflow: hidden;
	}

	&__close {
		position: absolute;
		top: 10px;
		right: 10px;

		display: none;

		color: #ff0000;
		text-shadow: 1px 1px 1px #000;

		cursor: pointer;
	}

	&__text {
		position: relative;

		margin: 10px 12px;

		font-size: 0.7rem;
		line-height: 1.7;
		text-shadow: 1px 1px 1px #000;


		span {
			box-decoration-break: clone;
			padding: 2px 5px;

			background-color: rgba(0,0,0,0.6);
			color: #F9C35F;
		}

		b {
			color: #FFE27A;
		}


		&.-margin-bot {
			margin-bottom: 25px;
		}
	}

	&__lvlup {
		position: absolute;
		right: 6px;
		bottom: -6px;

		font-size: 2rem;
		letter-spacing: -3px;
		text-shadow: 0 0 1px #231E03,
					0 0 15px #FFD000;

		color: #F3D771;
	}


	// component
	.c-item {
		width: 100%;

		z-index: 1;
	}


	&.-c-bloodline {
		border-color: #513636;
		background-color: #261313;


		#{$c}__text {
			span {
				color: #da6f6f;
			}

			b {
				color: #ffa5a5
			}
		}

		#{$c}__lvlup {
			text-shadow: 0 0 1px #230303,
						0 0 15px #d62727;

			color: #f37171;
		}
	}


	&:hover {
		z-index: 3;


		#{$c}__bg {
			img {
				opacity: 0.15;
			}
		}

		#{$c}__close {
			display: block;
		}
	}
}





/**
 * Ninja attributes section.
 */
.c-levelup-attributes {
	margin: 12px 30px 15px 20px;


	&__icon {
		position: absolute;
		top: -7px;
		left: -6px;

		font-size: 20px;

		color: #F9C35F;

		z-index: 1;
	}

	&__name {
		position: absolute;
		top: -1px;
		left: 8px;

		font-size: 0.5rem;
		text-transform: uppercase;

		color: #FBD892;

		z-index: 2;
	}

	&__gain {
		position: absolute;
		top: -6px;
		right: -12px;

		font-size: 1rem;
		letter-spacing: -1px;
		color: #FFE277;

		z-index: 1;


		b {
			font-weight: 600;
		}
	}


	// component
	.c-bar {
		width: 100%;
		height: 20px;

		margin-top: -1px;

		border: 0;

		background: none;


		&:before {
			content: none;
		}


		&__fill {
			min-width: 40px;

			border: 2px solid #211b17;
		}
	}
}
