.c-inventory-box {
	$c: &;

	@extend %c-box;

	flex-flow: row nowrap;

	min-width: 0;
	padding: 10px 8px;

	text-shadow: 1px 1px 1px #000;


	&__item {
		flex: 1 1 auto;

		display: flex;
		align-items: center;

		margin: 0 5px;


		// component
		.c-item {
			display: flex;

			width: 250px;
		}
	}

	&__equipped {
		flex: 0 0 30px;

		margin: 0 10px 0 10px;
		height: 27px;

		font-size: 20px;
		text-align: center;
		text-shadow: 1px 1px 1px #000;

		color: #90745A;
	}

	&__nr {
		flex: 0 0 70px;

		display: flex;
		align-items: center;
		justify-content: flex-end;

		width: 70px;
		margin: 0 10px;

		font-size: 0.85rem;
		font-weight: 600;

		color: #c5a17f;
	}

	&__value {
		flex: 0 0 70px;

		display: flex;
		align-items: center;
		justify-content: flex-end;

		width: 70px;
		margin: 0 10px;

		text-shadow: 1px 1px 1px #000;

		color: #e9b500;


		span {
			position: relative;
			top: 1px;

			padding-right: 5px;

			font-size: 0.75rem;
		}

		i {
			font-size: 20px;
		}
	}

	&__button {
		flex: 0 0 120px;

		display: flex;
		justify-content: flex-end;

		width: 120px;
		margin: 0 5px 0 10px;


		// component
		.c-button {
			min-width: 70px;
		}
	}
}
