.c-page-name {
	font-size: 2rem;
	text-align: center;
	text-shadow: 1px 1px 2px #000;

	color: #dec290;

	overflow: hidden;


	&:before,
	&:after {
		content: '';

		position: relative;

		display: inline-block;
		width: 50%;
		height: 2px;

		vertical-align: middle;

		background-color: #7b6131;
		border-bottom: 1px solid #000;
	}

	&:before {
		right: 0.5em;

		margin-left: -50%;
	}

	&:after {
		left: 0.5em;

		margin-right: -50%;
	}


	&.-size-s {
		font-size: 1rem;
	}

	&.-size-m {
		font-size: 1.3rem;
	}
}